@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 17;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1920; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 148;
$header-hgt-sp: 88;
$header-hgt-fx: 148;

// カラー管理
$colors: (
  clr-primary-default: #269cea,
  clr-primary-dark: #056fbd,
  clr-primary-dark2: #0d85b8,
  clr-primary-dark3: #005fb0,
  clr-primary-dark4: #0a2171,
  clr-primary-dark5: #006bb3,
  clr-primary-dark6: #0d99ff,
  clr-primary-light: #27b2ed,
  clr-primary-light2: #e0f2fd,
  clr-primary-light3: #66a6d1,
  clr-primary-light4: #84a5c0,

  clr-secondary-default: #e9692d,
  clr-secondary-dark: #d03030,
  clr-secondary-light: #f25353,

  clr-bg-01: #eef9fa,
  clr-bg-02: #d6e9f4,

  clr-sub-06: #6790b7,
  clr-sub-05: #a2689b,
  clr-sub-04: #cf727c,
  clr-sub-03: #d7a761,
  clr-sub-02: #a8bf71,
  clr-sub-01: #51aaa6,

  clr-mono-06: #333841,
  clr-mono-05: #646b7a,
  clr-mono-04: #9599a3,
  clr-mono-03: #babdc4,
  clr-mono-02: #dfdfe0,
  clr-mono-01: #f2faff,

  clr-text-primary: #361503,
  clr-text-secondary: #959da4,
  clr-text-placeholder: #c7c7c7,
  clr-text-placeholder2: #d7d7d7,
  clr-text-light: #efefef,

  clr-wht: #fff,
  clr-blk: #000,
);

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.03em;
  --line-height-default: 2.4;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "あおとゴシック R", sans-serif;
  --font-primary500: "あおとゴシック M", sans-serif;
  --font-primary600: "あおとゴシック DB", sans-serif;
  --font-primary700: "あおとゴシック B", sans-serif;
  --font-secondary: "Zen Kaku Gothic New", sans-serif;
  --font-jp: "Ryumin Regular KL";
  --font-jp550: "Ryumin Medium KL";
  --font-editor-ttl: "Zen Old Mincho", serif;
  --font-en: "Montserrat", sans-serif;
  --font-num: "Inter", sans-serif;

  //カラーを出力
  @each $key, $color in $colors {
    --#{$key}: #{$color};
  }

  // animation
  --transit-default: all 0.4s ease-out;
}

// SASS Maps

$z-index: (
  "header": 9999,
  "frame": 9995,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
