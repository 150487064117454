@use "../abstracts" as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(12);
  fill: var(--clr-primary-default);
  @include mq(sp) {
    @include rect(16);
  }
}
.icon-pdf {
  @include rect(27, 12);
}
.lps_sec {
  position: relative;
  &:nth-child(odd) {
    .bg-wht {
      background-color: var(--clr-bg-01);
    }
  }
  &:nth-child(even) {
    background-color: var(--clr-bg-01) !important;
  }
}
.lps_sec:nth-child(even) {
  .table1 td::before {
    border-bottom: 1px solid var(--clr-text-secondary);
  }
  .lps_parts--faq.faq-toggle .faq_item {
    border-top: 1px solid var(--clr-text-secondary);
    border-bottom: 1px solid var(--clr-text-secondary);
  }
  .lps_parts--flow:not(.flow-num) .flow_item:not(:last-child)::before {
    border-right: 1px solid var(--clr-text-secondary);
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}
