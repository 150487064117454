@use "../abstracts" as *;

/* ##############################################################################

    SEARCH

############################################################################## */
.search {
  .page_ttl--lead,
  .page_ttl--bg {
    display: none;
  }
  .page_ttl-has_bg {
    height: auto;
  }
  .page_ttl--area {
    padding-block: rem(96) 0;
    margin-right: 0;
    @include mq(sp) {
      padding-block: sprem(40) 0;
    }
  }
  .breadcrumbs {
    position: relative;
    bottom: auto;
    padding-block: rem(72) 0;
    padding-left: 0;
    @include mq(sp) {
      padding-block: sprem(40) 0;
    }
  }
  .page_ttl-has_bg > .inner {
    height: auto;
    max-width: vw(1440);
  }
  .ttl-01 {
    margin-bottom: rem(64) !important;
    @include mq(sp) {
      margin-bottom: sprem(32) !important;
    }
  }
}
#search-list .section_pdg {
  @include p-tb(rem(50));
}
#search-list .ttl-01 {
  margin: rem(70) 0 0;
}
