@use "../abstracts" as *;

/* bg
********************************************** */
.bg-primary {
  background-color: var(--clr-primary-default);
}
.bg-01 {
  background-color: var(--clr-bg-01);
}
.bg-02 {
  background-color: var(--clr-bg-02);
}
.bg-wht {
  background-color: var(--clr-wht);
}
