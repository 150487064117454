@use "../abstracts" as *;

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: rem(1400);
  @include auto-margin;
  .flow_item {
    position: relative;
    padding-left: rem(96);
    @include mq(sp) {
      padding-left: sprem(56);
    }
    &:not(:last-child) {
      padding-bottom: rem(64);
      @include mq(sp) {
        padding-bottom: sprem(32);
      }
      &::before {
        content: "";
        position: absolute;
        left: rem(30);
        border-right: 1px solid var(--clr-text-primary);
        @include mq(sp) {
          left: sprem(18);
        }
      }
    }
    &.clr-wht:not(:last-child)::before {
      border-color: var(--clr-wht);
    }
  }
  .ttl-03 {
    position: relative;
    font-family: var(--font-jp);
    line-height: 1.6;
    color: var(--clr-text-primary);
    &::before {
      position: absolute;
      border-radius: 50%;
    }
  }
  .clr-wht .ttl-03 {
    color: var(--clr-wht);
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32, 768, "%");
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300, 768, "%");
    }
    @include mq(sp) {
      &:not(:last-child) {
        margin-bottom: sprem(20);
      }
    }
  }
  .mce-content-body {
    margin-bottom: 0 !important;
    @include mq(sp, min, ps) {
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) {
  .flow_item:not(:last-child)::before {
    top: rem(64);
    bottom: rem(14);
    border-right: 1px solid #d8d8d8;
  }
  .ttl-03 {
    color: var(--clr-primary-dark3);
    font-family: var(--font-editor-ttl);
    font-size: rem(32);
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: rem(16);
    span {
      letter-spacing: -0.03em;
    }
    &::before {
      content: "";
      top: rem(18);
      left: rem(-76);
      @include rect(20);
      background-color: var(--clr-primary-dark3);
      @include mq(sp) {
        left: sprem(-46);
      }
    }
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num {
  .flow_item {
    counter-increment: number;
    &:not(:last-child)::before {
      top: rem(72);
      bottom: rem(20);
      @include mq(sp) {
        top: sprem(46);
        bottom: sprem(16);
      }
    }
  }
  .ttl-03 {
    margin-bottom: rem(16);
    @include mq(sp, min, ps) {
      font-size: rem(32);
    }
    &::before {
      content: counter(number, decimal-leading-zero);
      top: rem(-10);
      left: rem(-104);
      @include center-flex;
      @include rect(72);
      text-align: center;
      color: var(--clr-wht);
      background-color: var(--clr-primary-default);
      font-family: var(--font-en);
      font-size: rem(32);
      font-weight: 300;
      letter-spacing: -0.04em;
      padding-right: rem(2);
      @include mq(sp) {
        top: sprem(-4);
        left: sprem(-56);
        @include sprect(40);
        font-size: sprem(20);
      }
    }
  }
  .clr-wht .ttl-03::after {
    color: var(--clr-primary-default);
    background-color: var(--clr-wht);
  }
}
