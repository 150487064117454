@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  & > .inner {
    max-width: vw(1684);
    @include mq(sp) {
      max-width: rem(1684);
    }
  }
}
.page_ttl--area {
  flex: 1;
  margin-right: rem(40);
  padding-top: rem(214);
  @include mq(sp) {
    margin-right: 0;
    padding-top: sprem(40);
  }
}
.page_ttl--bg {
  width: rem(1120);
  height: rem(880);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: rem(140) 0 0 0;
  margin-right: calc(50% - 50vw);
  @include mq(sp) {
    height: sprem(240);
    border-radius: sprem(70) 0 0 0;
    margin-left: auto;
  }
}
.page_ttl-jp {
  font-size: rem(56);
  letter-spacing: 0.06em;
  padding-bottom: rem(24);
  @include mq(sp) {
    font-size: sprem(28);
    padding-bottom: sprem(10);
  }
}
.page_ttl-en {
  display: block;
  font-size: rem(17);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--clr-primary-light);
  padding-left: rem(13);
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(13);
    padding-left: sprem(4);
  }
}
.page_ttl--lead {
  width: vw(520);
  font-size: rem(17);
  padding-block: vw(120) vw(80);
  padding-left: vw(13);
  @include mq(pc) {
    width: vw(500);
    font-size: rem(21);
  }
  @include mq(sp) {
    width: 100%;
    font-size: sprem(14);
    padding-block: sprem(16) sprem(24);
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  .page_ttl--area {
    padding-block: vw(96) vw(80);
    margin-right: 0;
  }
  .page_ttl--lead {
    width: 100%;
    padding-block: vw(40) 0;
    @include mq(sp) {
      padding-block: sprem(20) 0;
    }
  }
  .breadcrumbs {
    padding-block: vw(110) vw(40) !important;
    @include mq(sp) {
      padding-block: sprem(36) sprem(24) !important;
    }
  }
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  min-height: rem(880);
  @include mq(sp) {
    height: fit-content;
    min-height: auto;
  }
  .breadcrumbs {
    padding-left: rem(13);
    padding-bottom: vw(120);
    @include mq(sp) {
      position: relative;
      bottom: auto;
      padding-left: sprem(4);
      padding-bottom: sprem(24);
    }
  }
}
.search  .page_ttl-has_bg { 
min-height: auto;
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-01-sub {
  span {
    line-height: 1.5;
    @include mq(sp) {
      line-height: 1.2;
    }
  }
}
.ttl-02,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(32);
    }
  }
}
.ttl-01 {
  &.clr-wht + .ttl-01-sub {
    color: var(--clr-wht);
    &::after {
      background-color: var(--clr-wht);
    }
  }
}
.ttl-01.font-jp {
  font-size: rem(58);
  letter-spacing: 0.06em;
  margin-bottom: rem(16);
  @include mq(1440) {
    font-size: rem(60);
  }
  @include mq(pc) {
    font-size: rem(62);
  }
  @include mq(sp) {
    font-size: sprem(30);
  }
}
.ttl-01-sub.font-en {
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: 0.03em;
  color: var(--clr-primary-light);
  @include mq(1440) {
    font-size: rem(18);
  }
  @include mq(pc) {
    font-size: rem(20);
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.ttl-01.font-en {
  font-size: rem(130);
  font-weight: 200;
  line-height: 1.7;
  letter-spacing: -0.04em;
  margin-bottom: rem(-24) !important;
  @include mq(sp) {
    font-size: sprem(56);
    line-height: 1;
  }
}
.ttl-01-sub.font-jp {
  font-family: var(--font-primary500);
  font-size: rem(20);
  line-height: 2;
  letter-spacing: 0.03em;
  &::before {
    content: "";
    display: inline-block;
    margin-right: rem(10);
    width: rem(12);
    height: rem(12);
    border-radius: 100%;
    background: var(--clr-primary-dark);
  }
  @include mq(1440) {
    font-size: rem(22);
  }
  @include mq(pc) {
    font-size: rem(24);
  }
  @include mq(sp) {
    font-size: sprem(14);
    padding-top: sprem(28);
  }
}
.ttl-01-sub {
  display: block;
  font-size: calc-fz(16);
  letter-spacing: 0.1em;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(80, 16, em);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
.ttl-01-sub_none {
  margin-bottom: rem(96) !important;
  @include mq(sp) {
    margin-bottom: sprem(40) !important;
  }
}
.ttl-02 {
  font-size: rem(44);
  line-height: 1.7;
  letter-spacing: 0.06em;
  @include mq(1440) {
    font-size: rem(46);
  }
  @include mq(pc) {
    font-size: rem(48);
  }
  @include mq(sp) {
    font-size: sprem(22);
  }
}
.ttl-sub {
  display: block;
  color: var(--clr-primary-light);
  font-size: rem(16);
  font-weight: 600;
  letter-spacing: 0.03em !important;
  &.top {
    margin-bottom: rem(15);
  }
  &.btm {
    margin-top: rem(15);
  }
  @include mq(1440) {
    font-size: rem(18);
  }
  @include mq(pc) {
    font-size: rem(20);
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.ttl-03 {
  font-family: var(--font-jp550);
  font-size: rem(28);
  margin-bottom: rem(32);
  @include mq(1440) {
    font-size: rem(30);
  }
  @include mq(pc) {
    font-size: rem(32);
  }
  @include mq(sp) {
    font-size: sprem(20);
    margin-bottom: sprem(16);
  }
}
.ttl-04 {
  font-size: rem(26);
  @include mq(1440) {
    font-size: rem(28);
  }
  @include mq(pc) {
    font-size: rem(30);
  }
  @include mq(sp) {
    font-size: sprem(20);
  }
}

.home--ttl {
  .ttl-en {
    font-size: rem(96);
    font-weight: 200;
    line-height: 1.7;
    letter-spacing: -0.04em;
    margin-left: rem(-12);
    @include mq(sp) {
      font-size: sprem(56);
      margin-left: sprem(-4);
    }
  }
  .ttl-jp {
    font-size: rem(40);
    line-height: 1.6;
    margin-bottom: vw(8);
    @include mq(sp) {
      font-size: sprem(25);
      margin-bottom: sprem(8);
    }
  }
  .ttl-en_small,
  .ttl-jp_small {
    display: block;
    &::before {
      content: "";
      display: inline-block;
      background-color: var(--clr-primary-dark5);
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 100px;
      margin-right: rem(8);
    }
  }
  .ttl-en_small {
    font-size: rem(12);
    font-weight: 600;
    line-height: 1.7;
    letter-spacing: 0.02em;
    &::before {
      width: rem(9);
      height: rem(9);
    }
    @include mq(1440) {
      font-size: rem(14);
    }
    @include mq(pc) {
      font-size: rem(16);
    }
    @include mq(sp) {
      font-size: sprem(11);
    }
  }
  .ttl-jp_small {
    font-size: rem(17);
    line-height: 2;
    margin-top: rem(-22);
    &::before {
      width: rem(12);
      height: rem(12);
    }
    @include mq(1440) {
      font-size: rem(19);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
    @include mq(sp) {
      font-size: sprem(14);
      margin-top: sprem(-11);
    }
  }
}
