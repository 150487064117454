@use '../abstracts' as *;

/* section-contact
********************************************** */
.section-contact {
  .cta_tel {
    width: 100%;
    max-width: rem(400);
    @include auto-margin;
  }
  .cta_tel a {
    padding: rem(24) rem(40);
    @include mq(sp) {
      display: block;
      padding: sprem(16);
    }
  }
}