@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: minmax(0, 1fr) rem(264);
  gap: rem(53);
  @include mq(med) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: sprem(64);
  }
  @include mq(sp) {
    gap: sprem(32);
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--clr-wht);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    color: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-primary-default);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.post--date {
  font-family: var(--font-en);
  font-size: rem(18);
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.06em;
  transition: var(--transit-default);
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(6);
  @include mq(sp) {
    gap: sprem(6);
  }
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(17);
  line-height: 2;
  background-color: var(--clr-text-light);
  color: var(--clr-text-primary);
  padding: rem(4) rem(16);
  border-radius: 100px;
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(13);
    padding: 0 sprem(12);
  }
}
.cat_list a:hover {
  color: var(--clr-primary-dark);
  background-color: var(--clr-primary-light2);
  opacity: 0.6;
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(39);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.side--ttl {
  font-family: var(--font-primary600);
  font-size: rem(24);
  line-height: 2;
  color: var(--clr-primary-dark);
}
.side--ttl span {
  display: block;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.7;
  color: var(--clr-primary-dark);
  margin-top: vw(-6);
}

/* --- list --- */
.side--list a {
  display: block;
  font-size: rem(17);
  line-height: 2;
  &:hover {
    color: var(--clr-primary-default);
  }
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.side--list .children .cat-item a {
  position: relative;
  padding-left: rem(20);
  &::before {
    content: "";
    position: absolute;
    top: rem(16);
    left: 0;
    width: vw(10);
    height: 1px;
    background-color: var(--clr-text-primary);
    transition: var(--transit-default);
    @include mq(1440) {
      top: rem(18);
    }
    @include mq(1280) {
      top: rem(20);
    }
    @include mq(sp) {
      top: sprem(13);
      width: sprem(10);
    }
  }
  &:hover::before {
    background-color: var(--clr-primary-default);
  }
}

/* --- post --- */
.posts-side {
  .post {
    display: grid;
    grid-template-columns: rem(64) minmax(0, 1fr);
    gap: rem(16);
    align-items: center;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--date {
    margin-bottom: 0;
  }
  .post--ttl {
    line-height: var(--line-height-hdr);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  .archive_list {
    position: relative;
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
  .archive_list a {
    display: block;
    padding: 0.25em 1em;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-bg-02);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .archive_list--label {
    margin-right: rem(8);
  }
  .archive_list--btn {
    cursor: pointer;
    border: none;
    outline: none;
    appearance: none;
    padding: 0.25em 4em 0.25em 2em;
    background-color: var(--clr-wht);
    border: 1px solid #ccc;
    color: inherit;
    font-family: var(--font-primary);
    font-size: calc-fz(16);
    line-height: var(--line-height-default);
    font-weight: 500;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0.4em;
      right: 0.8em;
      margin: auto;
      width: 0.6em;
      height: 0.6em;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .active .archive_list--btn::after {
    bottom: -0.2em;
    transform: rotate(225deg);
  }
  .archive_list--menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: var(--clr-wht);
    border: 1px solid #ccc;
    visibility: hidden;
    margin-top: -1px;
    opacity: 0;
    transition: var(--transit-default);
  }
  .active .archive_list--menu {
    visibility: visible;
    opacity: 1;
  }
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}

/* posts-members-only
**************************************** */
.archive-members-only .cat_list {
  display: none;
}
.posts-members-only {
  gap: rem(64) rem(40);
  .post {
    width: 31.4%;
    background-color: var(--clr-wht);
    box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
    border-radius: rem(30);
    padding: rem(40) rem(32);
  }
  .btn a {
    margin-inline: auto;
  }
  @include mq(sp) {
    gap: sprem(24);
  }
}

/* standard
**************************************** */

/* --- standard--archive --- */
.standard--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.standard--archive li {
  @include auto-margin(8);
}
.standard--archive a {
  color: var(--clr-wht);
}

/* --- posts-standard --- */
.posts-standard {
  .post {
    padding-block: rem(16);
    margin-bottom: 0;
    border-bottom: 1px solid #dedede;
    transition: var(--transit-default);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin-block: auto;
      width: rem(40);
      height: rem(40);
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/btn-hover.png);
      background-repeat: no-repeat;
      background-size: 100%;
      opacity: 0;
      transition: var(--transit-default);
      @include mq(sp) {
        content: none;
      }
    }
    &:hover {
      .post--date,
      .post--link,
      .cat_list a {
        color: var(--clr-primary-dark);
      }
      .cat_list a {
        background-color: var(--clr-primary-light2);
      }
      &::before {
        opacity: 1;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    @include mq(sp) {
      display: block;
      padding-block: sprem(14);
    }
  }
  .post--info {
    @include mq(sp, min, ps) {
      margin-right: rem(49);
    }
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--txtarea {
    flex: 1;
    padding-right: rem(64);
    @include mq(sp) {
      padding-right: 0;
    }
  }
  .post--ttl {
    font-size: rem(17);
    line-height: 2;
    @include mq(1440) {
      font-size: rem(19);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  .post--info.flex .post--date + .cat_list {
    margin-left: rem(40);
    @include mq(sp) {
      margin-left: sprem(12);
    }
  }
}

/* --- post-type-information --- */
.post-type-information .cat_list,
.search .posts-information .cat_list {
  height: fit-content;
  padding-bottom: vw(5);
  a {
    color: var(--clr-primary-dark);
    background-color: var(--clr-primary-light2);
    &:hover {
      opacity: 0.7;
    }
  }
  @include mq(sp) {
    padding-bottom: sprem(12);
  }
}
.post-type-information .container,
.post-type-study .container,
.post-type-news .container,
.post-type-statement .container,
.post-type-members-only .container {
  background-color: var(--clr-bg-01);
}
.archive-information .main_column {
  flex: 1;
}
.archive-study .main_column,
.single-study .study--info,
.archive-news .main_column,
.single-news .news--info,
.archive-statement .posts-standard,
.single-statement .statement--info,
.archive-members-only .posts-standard,
.single-members-only .members-only--info,
.search .posts-standard {
  border-radius: rem(30);
  background-color: var(--clr-wht);
  box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
  padding: vw(48);
  @include mq(sp) {
    border-radius: sprem(15);
    padding: sprem(24);
  }
}
.post-type-information .container {
  & > .inner {
    max-width: vw(1673);
  }
  .main_side {
    display: flex;
    justify-content: center;
    @include mq(sp) {
      flex-direction: column;
    }
  }
}
.post-type-information .side_column,
.information--toc {
  height: fit-content;
  border-radius: rem(30);
  background-color: var(--clr-wht);
  box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
  padding: vw(48) vw(47) vw(64);
  @include mq(sp) {
    padding: sprem(24);
  }
}
.posts-information,
.information--info {
  width: vw(1200);
  height: fit-content;
  border-radius: rem(30);
  background-color: var(--clr-wht);
  box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
  margin-inline: auto;
  @include mq(sp) {
    width: 100%;
  }
}

/* --- archive-information --- */
.archive-information {
  .container {
    // padding-top: vw(79);
    padding-top: vw(120);
    @include mq(sp) {
      // padding-top: sprem(48);
      padding-top: sprem(60);
    }
  }
  .main_side {
    padding-bottom: vw(152);
    @include mq(sp) {
      padding-bottom: sprem(60);
    }
  }
}

/* --- posts-information --- */
.posts-information {
  width: vw(1200);
  height: fit-content;
  border-radius: rem(30);
  background-color: var(--clr-wht);
  box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
  padding-block: vw(54) vw(22);
  margin-inline: auto;
  .post {
    padding-block: vw(29) vw(34);
    &:not(:first-child) {
      border-top: 1px solid var(--clr-text-placeholder);
    }
    @include mq(sp) {
      padding-block: sprem(18) sprem(24);
    }
  }
  .post--txtarea {
    width: vw(930);
    padding-bottom: vw(10);
    @include mq(sp) {
      width: 100%;
    }
  }
  .post--ttl {
    font-family: var(--font-primary500);
    font-size: rem(24);
    line-height: 2;
    overflow: hidden;
    margin-right: vw(24);
    padding-bottom: vw(5);
    @include mq(sp) {
      font-size: sprem(18);
      line-height: 1.7;
      margin-right: 0;
      padding-bottom: sprem(8);
    }
  }
  .post--date {
    font-family: var(--font-primary);
    font-weight: 400;
    letter-spacing: -0.03em;
    color: #606060;
    padding-right: vw(40);
    @include mq(sp) {
      padding-right: 0;
      padding-bottom: sprem(6);
    }
  }
  .post--conts {
    position: relative;
    z-index: 1;
    gap: vw(10);
    @include mq(sp) {
      gap: sprem(8);
    }
  }
  @include mq(sp) {
    width: 100%;
    .post--info {
      flex-direction: column-reverse;
    }
  }
}
.posts-information--inner {
  width: vw(1080);
  margin-inline: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.post--info--txt {
  width: vw(600);
  font-size: rem(17);
  line-height: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.info--btn--wrap {
  gap: vw(8);
  margin-left: auto;
  @include mq(sp) {
    gap: sprem(6);
  }
}
.post--info--btn {
  height: fit-content;
  @include mq(sp) {
    width: 90%;
  }
}
.download--wrap {
  gap: vw(8);
  @include mq(sp) {
    gap: sprem(8);
  }
}
.post--info--btn a {
  height: fit-content;
  display: block;
  font-family: var(--font-secondary);
  font-size: rem(17);
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.07em;
  color: var(--clr-primary-dark);
  border-radius: 100px;
  border: 2px solid var(--clr-primary-dark);
  background-color: var(--clr-wht);
  padding: vw(12) vw(36);
  &:hover {
    border: 2px solid var(--clr-wht);
    color: var(--clr-wht) !important;
    background-color: var(--clr-primary-dark);
    .icon-new_tab {
      fill: var(--clr-wht);
    }
  }
  &::before {
    content: none;
  }
  .icon-new_tab {
    margin-left: vw(6);
    fill: var(--clr-primary-dark);
    @include mq(sp) {
      margin-left: sprem(6);
    }
  }
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(15);
    text-align: center;
    padding: sprem(10) sprem(12);
  }
}

.search .posts-information {
  padding-block: vw(24);
  .post {
    margin-inline: vw(40);
    @include mq(sp) {
      margin-inline: sprem(24);
    }
  }
  @include mq(sp) {
    padding-block: sprem(8);
  }
}
