@use "../abstracts" as *;

/* --- blc:not(:last-child) --- */
.blc:not(:last-child) {
  margin-bottom: rem(120);
  @include mq(sp) {
    margin-bottom: sprem(40);
  }
}
.blc-sm:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
