@use "../abstracts" as *;

/* fixed_btn--list
********************************************** */
.fixed_btn--list {
  position: fixed;
  bottom: 5%;
  right: rem(20);
  z-index: z(aside);
  li:first-child {
    margin-bottom: rem(4);
  }
  // @include mq(sp) {
  //   display: flex;
  //   width: 93.5%;
  //   bottom: 0;
  //   right: 0;
  //   left: 0;
  //   margin-inline: auto;
  //   li {
  //     width: 50%;
  //   }
  // }
}
.fixed_btn-join,
.fixed_btn-mail {
  position: relative;
  min-width: rem(70);
  @include center-flex;
  overflow: hidden;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  width: rem(70);
  font-family: var(--font-primary600);
  font-size: rem(15);
  border-radius: rem(10) 0 0 rem(10);
  margin-left: auto;
  pointer-events: auto;
  &::before {
    content: "";
    position: absolute;
    mask-repeat: no-repeat;
    mask-size: 100%;
    transition: var(--transit-default);
    // @include mq(sp) {
    //   position: relative;
    //   display: inline-block;
    // }
  }
  @include mq(1440) {
    font-size: rem(17);
  }
  @include mq(pc) {
    font-size: rem(19);
  }
  // @include mq(sp) {
  //   width: 100%;
  //   writing-mode: initial;
  //   border-radius: rem(10) rem(10) 0 0;
  //   padding: sprem(12) 0 sprem(16) !important;
  // }
}
.fixed_btn-join {
  color: var(--clr-wht);
  padding: rem(68) rem(17) rem(36) rem(21);
  background-color: var(--clr-secondary-default);
  border: 3px solid var(--clr-secondary-default);
  cursor: pointer !important;
  &::before {
    top: rem(24);
    left: rem(19);
    width: rem(32);
    height: rem(32);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-join.svg);
    background-color: var(--clr-wht);
    @include mq(1280) {
      left: rem(18);
    }
    // @include mq(sp) {
    //   top: auto;
    //   left: auto;
    //   margin-right: sprem(5);
    // }
  }
  &:hover {
    color: var(--clr-secondary-default);
    background-color: var(--clr-wht);
    &::before {
      background-color: var(--clr-secondary-default);
    }
  }
}
.fixed_btn-mail {
  color: var(--clr-primary-default);
  padding: rem(55) rem(25) rem(24) rem(30);
  background-color: var(--clr-wht);
  border: 3px solid var(--clr-primary-default);
  &::before {
    top: rem(23);
    left: rem(23);
    width: rem(22);
    height: rem(22);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-mail.svg);
    background-color: var(--clr-primary-default);
    @include mq(1280) {
      left: rem(21);
    }
    // @include mq(sp) {
    //   top: auto;
    //   left: auto;
    //   margin-right: sprem(8);
    // }
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    &::before {
      background-color: var(--clr-wht);
    }
  }
}

/* hero
********************************************** */
.hero--wrap {
  margin-bottom: rem(144);
  @include mq(sp) {
    overflow: hidden;
    margin-bottom: sprem(210);
  }
}
.hero {
  position: relative;
  width: vw(936);
  height: vw(824);
  margin-left: vw(20);
  &::before {
    content: "";
    position: absolute;
    bottom: vw(-30);
    left: 0;
    width: vw(936);
    height: vw(824);
    background-image: url(/wp/wp-content/themes/standard_sass/images/hero-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 rem(75) 0;
    @include mq(sp) {
      bottom: sprem(-15);
      width: 100% !important;
      height: sprem(330);
    }
  }
  img {
    border-radius: 0 0 rem(75) 0;
  }
  @include mq(lg) {
    width: vw(788);
    &::before {
      width: vw(788);
    }
  }
  @include mq(sp) {
    width: 80% !important;
    height: sprem(330);
  }
}
.hero--catch--wrap {
  padding-bottom: vw(24);
  @include mq(sp) {
    padding-bottom: sprem(24);
  }
}
.hero--cont {
  text-align: right;
  margin-right: vw(122);
  padding-top: vw(42);
  @include mq(1440) {
    margin-right: vw(72);
  }
  @include mq(sp) {
    width: 84% !important;
    padding-top: sprem(32);
    margin-inline: auto;
    white-space: normal;
  }
}
.hero--catch {
  font-size: rem(48);
  line-height: 1.5;
  letter-spacing: 0.06em;
  .blue {
    color: var(--clr-primary-dark);
    letter-spacing: 0.06em;
  }
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.hero--catch-en {
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: #4da4c9;
  padding-bottom: vw(24);
  @include mq(1440) {
    font-size: rem(14);
  }
  @include mq(pc) {
    font-size: rem(16);
  }
  @include mq(sp) {
    font-size: sprem(10);
    padding-bottom: sprem(24);
  }
}
.hero--lead {
  font-size: rem(17);
  line-height: 2;
  padding-bottom: vw(122);
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.hero--catch,
.hero--catch-en,
.hero--lead {
  .txt_wrap span {
    display: inline-block;
    transform: translateY(rem(16));
    opacity: 0;
    transition: all 1s cubic-bezier(0.39, -0.01, 0.16, 0.78);
  }
  &.active {
    .txt_wrap span {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

// hero_slides-rgt
.hero_slides-rgt {
  position: absolute;
  right: 0;
  width: vw(805);
  img {
    width: vw(805);
    height: vw(460);
    border-radius: rem(75) 0 0 rem(75);
  }
  &::before {
    content: "";
    position: absolute;
    bottom: vw(-30);
    right: vw(8);
    width: vw(820);
    height: vw(456);
    background-image: url(/wp/wp-content/themes/standard_sass/images/hero-bg02.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: rem(75) 0 0 rem(75);
    @include mq(sp) {
      bottom: sprem(-15);
      width: 104%;
      height: sprem(180);
      border-radius: sprem(38) 0 0 sprem(38);
    }
  }
  @include mq(sp) {
    width: 70%;
    img {
      width: 100%;
      height: sprem(180);
      border-radius: sprem(38) 0 0 sprem(38);
    }
  }
}

// hero_slide
.hero_slide {
  overflow: hidden;
}
.hero_slide-sp {
  display: none !important;
}
.hero_slides-lft,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}
.hero_slide--dots {
  position: absolute;
  left: rem(44);
  bottom: rem(77);
  color: var(--clr-text-placeholder2);
  @include mq(sp) {
    left: sprem(36);
    bottom: sprem(16);
  }
}
.hero_slide--dots .slick-dots {
  display: flex;
  @include center-flex;
  &.pc-none {
    display: none;
  }
  &.sp-none {
    display: flex;
  }
  @include mq(sp) {
    &.sp-none {
      display: none;
    }
    &.pc-none {
      display: flex;
    }
  }
}
.hero_slide--dots .slick-dots li {
  position: relative;
  font-size: rem(18);
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.01em;
  margin-right: rem(40);
  transition: var(--transit-default);
  cursor: pointer;
  &.slick-active {
    color: #109edb;
    &::before {
      content: "";
      position: absolute;
      width: vw(6);
      height: vw(6);
      border-radius: 50%;
      background-color: #109edb;
      top: vw(-6);
      left: 0;
      right: 0;
      margin: auto;
      transition: var(--transit-default);
      @include mq(sp) {
        top: sprem(-6);
        width: sprem(5);
        height: sprem(5);
      }
    }
  }
}
@include mq(sp) {
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
}

/* home_news
********************************************** */
.home_news {
  margin-bottom: vw(104);
  @include mq(sp) {
    margin-bottom: sprem(56);
  }
}
.home_news .home--ttl {
  margin-bottom: rem(36);
  @include mq(sp) {
    margin-bottom: sprem(18);
  }
}
.home_news--content {
  position: relative;
  z-index: 1;
}
.home_news--content .no_post {
  padding-top: rem(16);
}
.home_news--tab {
  gap: vw(4);
}
.home_news--tab li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(200);
  height: rem(56);
  font-family: var(--font-primary500);
  font-size: rem(17);
  line-height: 1;
  text-align: center;
  background-color: var(--clr-text-light);
  border-radius: rem(10) rem(10) 0 0;
  cursor: pointer;
  transition: var(--transit-default);
  &:hover:not(.select) {
    opacity: 0.6;
  }
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    width: 33%;
  }
}
.home_news--tab li.select {
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
}
.home_news--tab_contents .tab_content {
  display: none;
  padding: rem(23) rem(97) rem(40) rem(63);
  border-radius: 0 rem(26) rem(26) rem(26);
  background: var(--clr-wht);
  box-shadow: 0 0 rem(32) 0 rgba(0, 0, 0, 0.05);
  @include mq(sp) {
    padding: sprem(12) sprem(28) sprem(22);
    border-radius: 0 0 sprem(26) sprem(26);
  }
}
.home_news--tab_contents .tab_content.select {
  display: block;
}
.home_news--tab_contents .tab_content .post {
  &:first-child {
    border-top: none;
  }
}
.news-btn {
  width: fit-content;
  margin-left: auto;
  margin-top: vw(18);
  @include mq(sp) {
    margin-top: sprem(16);
  }
}
.news-btn a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: rem(17);
  line-height: 2;
  &::before {
    content: "";
    display: inline-block;
    width: rem(23);
    height: rem(23);
    background-color: var(--clr-primary-dark);
    border-radius: 100px;
    margin-right: rem(8);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: rem(8);
    margin-block: auto;
    width: rem(7);
    height: rem(6);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/polygon.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--clr-wht);
    transform: rotate(-90deg);
  }
  &:hover {
    color: var(--clr-primary-default);
  }
  @include mq(sp) {
    width: fit-content;
    margin-inline: auto;
  }
}

/* home_info
********************************************** */
.home_info {
  position: relative;
  margin-bottom: rem(64);
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1;
  }
  &::before {
    right: 0;
    top: vw(-464);
    width: rem(838);
    height: rem(724);
    background-image: url(/wp/wp-content/themes/standard_sass/images/news-deco01.png);
    @include mq(sp) {
      top: sprem(-224);
      width: sprem(280);
      height: sprem(241);
    }
  }
  &::after {
    left: 0;
    top: vw(-628);
    width: rem(1323);
    height: rem(1982);
    background-image: url(/wp/wp-content/themes/standard_sass/images/news-deco02.png);
    @include mq(sp) {
      top: sprem(-16);
      width: sprem(294);
      height: sprem(441);
    }
  }
  @include mq(sp) {
    margin-bottom: sprem(40);
  }
}
.info--wrap {
  flex-wrap: nowrap;
  padding-top: rem(60);
  @include mq(sp) {
    gap: sprem(16);
    padding-top: sprem(30);
  }
}
.info--ttl {
  font-family: var(--font-primary500);
  font-size: rem(24);
  line-height: 1.7;
  color: var(--clr-primary-dark5);
  margin-bottom: rem(8);
  @include mq(1440) {
    font-size: rem(26);
  }
  @include mq(pc) {
    font-size: rem(28);
  }
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.info--ttl-en {
  display: block;
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: var(--clr-primary-light3);
  opacity: 0.6;
  margin-bottom: rem(24);
  @include mq(1440) {
    font-size: rem(14);
  }
  @include mq(pc) {
    font-size: rem(16);
  }
  @include mq(sp) {
    font-size: sprem(11);
    margin-bottom: sprem(14);
  }
}
.info--box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  min-height: rem(380);
  border: 4px solid var(--clr-wht);
  border-radius: rem(32);
  background-color: var(--clr-wht);
  box-shadow: 0 0 rem(32) 0 rgba(0, 0, 0, 0.04);
  padding: rem(48) rem(48) rem(23);
  transition: var(--transit-default);
  &:nth-child(2) {
    margin-inline: vw(16);
    @include mq(sp) {
      margin-inline: 0;
    }
  }
  p {
    font-size: rem(17);
    @include mq(1440) {
      font-size: rem(19);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
  &:hover {
    border: 4px solid var(--clr-primary-dark6);
    background-color: var(--clr-mono-01);
  }
  @include mq(pc) {
    height: rem(440);
  }
  @include mq(sp) {
    gap: sprem(20);
    min-height: auto;
    height: fit-content;
    padding: sprem(24) sprem(24) sprem(12);
  }
}
.home_info .btn.btn2 {
  margin-right: rem(-16);
  @include mq(sp) {
    margin-right: 0;
  }
}
.home_info .btn.btn2 a {
  position: initial;
  padding: 0;
  margin-left: auto;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    border-radius: 0;
  }
  &::after {
    content: none;
  }
  &:hover span {
    &::before {
      background-color: var(--clr-wht);
    }
    &::after {
      background-color: var(--clr-primary-dark);
    }
  }
  @include mq(sp) {
    margin-right: 0;
  }
}
.home_info .btn.btn2 span {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  color: var(--clr-primary-dark5);
  font-family: var(--font-secondary);
  font-weight: 500;
  letter-spacing: -0.07em;
  margin-left: auto;
  &::before {
    content: "";
    display: block;
    width: rem(40);
    height: rem(40);
    background-color: var(--clr-primary-dark);
    border: 1px solid var(--clr-primary-dark);
    border-radius: 100%;
    transition: var(--transit-default);
    margin-right: rem(8);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: rem(12);
    bottom: 0;
    margin: auto;
    width: rem(16);
    height: rem(15);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/arrow_btn2.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--clr-wht);
    transition: var(--transit-default);
  }
}

/* home_member
********************************************** */
.home_member {
  position: relative;
  padding-bottom: vw(127);
  &::before {
    content: "";
    position: absolute;
    bottom: vw(-140);
    right: vw(10);
    width: vw(515);
    height: vw(667);
    background-image: url(/wp/wp-content/themes/standard_sass/images/news-deco03.png);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1;
    @include mq(sp) {
      bottom: sprem(-70);
      width: sprem(258);
      height: sprem(334);
    }
  }
  @include mq(sp) {
    padding-bottom: sprem(64);
  }
}
.member--wrap {
  box-shadow: 0 0 rem(28) 0 rgba(0, 0, 0, 0.02);
  @include mq(sp) {
    gap: sprem(10);
  }
}
.member--box {
  position: relative;
  display: flex;
  align-items: center;
  height: vw(179);
  background-color: var(--clr-wht);
  border: 4px solid var(--clr-wht);
  transition: var(--transit-default);
  &::before {
    content: "";
    display: inline-block;
    background-image: url(/wp/wp-content/themes/standard_sass/images/member-chat.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &:hover {
    border: 4px solid var(--clr-primary-dark6);
    background: var(--clr-mono-01);
  }
  @include mq(sp) {
    height: fit-content;
    border-radius: sprem(20) !important;
    box-shadow: 0 0 1.8823529412rem 0 rgba(0, 0, 0, 0.04);
    padding-block: sprem(16);
  }
}
.member--wrap .member--box:first-child {
  border-radius: rem(32) 0 0 rem(32);
  padding-left: rem(57);
  &::before {
    width: rem(75);
    height: rem(75);
    background-image: url(/wp/wp-content/themes/standard_sass/images/member-chat.svg);
    margin-right: rem(24);
    @include mq(sp) {
      width: sprem(40);
      height: sprem(40);
    }
  }
}
.member--wrap .member--box:nth-child(2) {
  border-radius: 0;
  padding-left: rem(50);
  &::before {
    width: rem(72);
    height: rem(72);
    background-image: url(/wp/wp-content/themes/standard_sass/images/member-account.svg);
    margin-right: rem(32);
    @include mq(sp) {
      width: sprem(42);
      height: sprem(42);
      margin-right: sprem(22);
    }
  }
  @include mq(sp) {
    padding-left: sprem(46);
  }
}
.member--wrap .member--box:last-child {
  border-radius: 0 rem(32) rem(32) 0;
  padding-left: rem(56);
  &::before {
    width: rem(70);
    height: rem(70);
    background-image: url(/wp/wp-content/themes/standard_sass/images/member-bag.svg);
    margin-right: rem(32);
    @include mq(sp) {
      width: sprem(44);
      height: sprem(44);
      margin-right: sprem(24);
    }
  }
}
.member-ttl {
  display: block;
  font-family: var(--font-primary500);
  font-size: rem(20);
  line-height: 1.7;
  margin-bottom: rem(8);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  @include mq(1440) {
    font-size: rem(22);
  }
  @include mq(pc) {
    font-size: rem(24);
  }
  @include mq(sp) {
    font-size: sprem(17);
    margin-bottom: sprem(4);
  }
}
.member--ttl-en {
  display: block;
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: var(--clr-primary-light3);
  opacity: 0.6;
  @include mq(1440) {
    font-size: rem(14);
  }
  @include mq(pc) {
    font-size: rem(16);
  }
  @include mq(sp) {
    font-size: sprem(11);
  }
}

/* home_about
********************************************** */
.home_about {
  background-color: #e0f3f6;
  padding-block: vw(91) vw(101);
  .btn2 a {
    margin-inline: auto;
  }
  @include mq(sp) {
    padding-block: sprem(40) sprem(51);
    .btn {
      width: fit-content;
      margin-inline: auto;
    }
  }
}
.about--txt {
  padding-block: vw(58) vw(80);
  p {
    font-size: rem(17);
    &:first-child {
      padding-bottom: 2.3rem;
      @include mq(sp) {
        padding-bottom: sprem(16);
      }
    }
    @include mq(1440) {
      font-size: rem(19);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
  @include mq(sp) {
    padding-block: sprem(29) sprem(80);
  }
}
.about--box {
  position: sticky;
  top: vw(200);
  border-radius: 0 rem(30) rem(30) rem(30);
  background-color: var(--clr-wht);
  box-shadow: 0 0 rem(32) 0 rgba(0, 0, 0, 0.05);
  padding: vw(23) vw(119) vw(31) vw(22);
  margin-bottom: vw(160);
  &:first-child .about--num {
    margin-right: vw(32);
    @include mq(sp) {
      margin-right: sprem(20);
    }
  }
  &.box03 {
    margin-bottom: vw(111);
  }
  @include mq(sp) {
    position: relative;
    top: auto;
    padding: sprem(18);
    margin-bottom: sprem(80);
  }
}
.about--img img {
  width: vw(600) !important;
  border-radius: rem(30);
  object-fit: cover;
  @include mq(sp) {
    width: 100% !important;
    height: sprem(180);
  }
}
.about--txtarea {
  flex: 1;
  margin-left: vw(57);
  @include mq(sp) {
    margin-left: 0;
    padding-top: sprem(20);
    & > .flex {
      align-items: start;
    }
  }
}
.about--num {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--clr-primary-dark5);
  line-height: 1.7;
  margin-right: vw(24);
  &::before {
    content: "";
    position: absolute;
    left: rem(10);
    bottom: rem(4);
    width: rem(72);
    height: rem(6);
    background-color: var(--clr-primary-light2);
    border-radius: 100px;
    @include mq(sp) {
      left: sprem(5);
      bottom: sprem(2);
      width: sprem(36);
      height: sprem(3);
    }
  }
  @include mq(sp) {
    margin-right: sprem(20);
  }
}
.about--num .txt {
  font-size: rem(17);
  font-weight: 500;
  letter-spacing: -0.04em;
  @include mq(sp) {
    font-size: sprem(11);
  }
}
.about--num .num {
  font-size: rem(102);
  font-weight: 400;
  letter-spacing: -0.04em;
  margin-top: vw(-40);
  @include mq(sp) {
    font-size: sprem(51);
    margin-top: sprem(-20);
  }
}
.about--ttl {
  flex: 1;
  font-size: rem(32);
  line-height: 1.7;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.about--box--txt {
  font-size: rem(17);
  padding-top: vw(25);
  margin-left: vw(10);
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(14);
    padding-top: sprem(14);
    margin-left: 0;
  }
}
.about--tag {
  position: absolute;
  top: vw(69);
  left: vw(-133);
  width: vw(201);
  height: vw(64);
  transform: rotate(-90deg);
  background-color: var(--clr-primary-default);
  border-radius: rem(10) rem(10) 0 0;
  color: var(--clr-wht);
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.7;
  span {
    letter-spacing: -0.04em;
    &:first-child {
      padding-right: vw(15);
    }
  }
  @include mq(sp) {
    top: sprem(-40);
    left: 0;
    transform: none;
    width: sprem(160) !important;
    height: sprem(40);
  }
}

/* home_ibclc
********************************************** */
.home_ibclc {
  position: relative;
  background-color: var(--clr-primary-default);
  z-index: 1;
}
.ibclc--wrap {
  position: relative;
  overflow: clip;
  padding-bottom: vw(270);
  @include mq(sp) {
    padding-bottom: sprem(200);
  }
}
.ibclc-bg {
  position: absolute;
  top: -20%;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  gap: rem(32);
  rotate: 11.4deg;
  opacity: 0.4;
  pointer-events: none;
  span {
    flex-shrink: 0;
    width: 25%;
    background-size: cover;
    @include mq(sp) {
      width: sprem(200);
    }
    img {
      width: 100% !important;
      animation: translate 28s linear infinite;
      animation-direction: normal;
      padding-bottom: rem(20);
    }
    &:nth-child(odd) img {
      animation: translate 20s linear infinite;
      animation-direction: reverse;
    }
  }
  @include mq(sp) {
    top: 20%;
    left: -50%;
    gap: sprem(14);
  }
}
.ibclc--inner {
  width: fit-content;
  background-color: var(--clr-wht);
  padding: vw(120) vw(149) vw(122) vw(161);
  border-radius: 0 0 rem(140) 0;
  @include mq(sp) {
    width: 94%;
    padding: sprem(40) sprem(24) sprem(56) sprem(32);
    border-radius: 0 0 sprem(70) 0;
  }
}
.ibclc--inner .home--ttl {
  position: relative;
  .ttl-jp {
    margin-bottom: rem(24);
    padding-bottom: rem(30);
  }
  span {
    font-size: rem(32);
    line-height: 1.6;
    @include mq(sp) {
      font-size: sprem(21);
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: rem(72);
    height: rem(6);
    background-color: var(--clr-primary-light2);
    border-radius: 100px;
  }
}
.ibclc--txt {
  width: vw(570);
  font-size: rem(17);
  padding-bottom: rem(54);
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    width: 100%;
    font-size: sprem(14);
    padding-bottom: sprem(28);
  }
}
.ibclc--inner .btn2 {
  margin-bottom: rem(15);
  a {
    padding-block: 0;
    height: rem(64);
    display: flex;
    align-items: center;
  }
  @include mq(sp) {
    width: fit-content;
  }
}
.ibclc--inner .footer--relation {
  padding-top: rem(40);
  @include mq(sp) {
    padding-top: sprem(20);
  }
  .relation--wrap a {
    border: 1px solid var(--clr-text-secondary);
    @include mq(sp) {
      border: 0.5px solid var(--clr-text-secondary);
    }
  }
}
.relation--txt {
  font-size: rem(17);
  @include mq(1440) {
    font-size: rem(18);
  }
  @include mq(pc) {
    font-size: rem(20);
  }
  @include mq(sp) {
    font-size: sprem(13);
  }
}

/* home_partner
********************************************** */
.home_partner {
  background-color: #e0f3f6;
  padding-block: vw(91) vw(101);
  .btn2 a {
    margin-inline: auto;
  }
  @include mq(sp) {
    padding-block: sprem(40) sprem(25);
    .btn {
      width: fit-content;
      margin-inline: auto;
    }
  }
}
.partner--txt {
  padding-block: vw(58) vw(58);
  p {
    font-size: rem(17);
    &:first-child {
      padding-bottom: 2.3rem;
      @include mq(sp) {
        padding-bottom: sprem(16);
      }
    }
    @include mq(1440) {
      font-size: rem(19);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
  @include mq(sp) {
    padding-block: sprem(29) sprem(40);
  }
}

.home_partner .relation--wrap a {
  padding: .5em;
}
.home_partner .relation--wrap:first-of-type img {
  height: auto;
  width: rem(320);
  @include mq(sp) {
    width: sprem(250);
  }
}