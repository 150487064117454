@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  background-color: var(--clr-wht);
  z-index: z(top);
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(43);
    padding-block: rem(40) rem(26);
    @include mq(1440) {
      padding-top: rem(34);
    }
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: rem(30);
    }
  }
}
.template-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  width: rem(287) !important;
  @include mq(med) {
    width: sprem(192) !important;
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(24) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--clr-bg-02);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.gnav a {
  font-family: var(--font-primary500);
  font-size: rem(18);
  @include mq(med, min, ps) {
    @include center-flex;
  }
  @include mq(1440) {
    font-size: rem(17);
  }
  @include mq(sp) {
    font-size: sprem(15);
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--other {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--clr-primary-default);
    }
  }
}
.gnav--link {
  padding: 0 rem(32);
  @include mq(1440) {
    padding: 0 rem(24);
  }
  @include mq(pc) {
    padding: 0 rem(18);
  }
  @include mq(med) {
    display: block;
    padding: sprem(2) 0;
  }
  @include mq(med, min, ps) {
    text-align: center;
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: sprem(10);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid var(--clr-primary-default);
    border-right: 1px solid var(--clr-primary-default);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-primary-dark5);
  }
}
.gnav--menu > li.menu-item-has-children .gnav--link span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: rem(8);
    width: rem(8);
    height: rem(7);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/polygon.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 2px;
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  display: block;
  width: rem(250);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  z-index: 100;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  @include mq(1440) {
    width: rem(300);
  }
  @include mq(med) {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-primary-default);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a {
  display: block;
  padding: rem(16) rem(24);
  @include mq(med, min, ps) {
    background-color: var(--clr-wht);
  }
  &:hover {
    @include mq(med, min, ps) {
      color: var(--clr-primary-dark5);
      background-color: var(--clr-primary-light2);
    }
  }
  span::before {
    content: none !important;
  }
}
.head_sub_nav li:not(:last-child) a {
  border-bottom: 1px solid var(--clr-text-light);
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-20);
    @include sprect(56);
    transition: var(--transit-default);
    color: var(--clr-primary-default);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64);
    border-left: 1px solid #e7e5e4;
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
    &.active {
      background-color: var(--clr-bg-02);
    }
  }
}
.gnav_btn--lines {
  position: relative;
  width: rem(56);
  height: rem(34);
  pointer-events: none;
  @include mq(sp) {
    width: sprem(36);
    height: sprem(20);
  }
}
.gnav_btn--lines span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--clr-wht);
  transition: var(--transit-default);
  border-radius: 5px;
  &:nth-of-type(1) {
    top: 0;
  }
  &:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  &:nth-of-type(3) {
    bottom: 0;
  }
}
.active .gnav_btn--lines span {
  &:nth-of-type(1) {
    transform: translateY(rem(18)) rotate(-30deg);
    @include mq(sp) {
      transform: translateY(sprem(10)) rotate(-30deg);
    }
  }
  &:nth-of-type(2) {
    opacity: 0;
  }
  &:nth-of-type(3) {
    transform: translateY(rem(-14)) rotate(30deg);
    @include mq(sp) {
      transform: translateY(sprem(-8)) rotate(30deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-primary-default);
    }
  }
}
.gnav-container {
  display: flex;
  flex-direction: column;
  margin-right: rem(172);
  margin-left: rem(40);
  .gnav-row:first-child {
    margin-bottom: rem(5);
  }
}
.gnav--other {
  text-align: center;
}
.gnav--other li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--tel,
.gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
}
.gnav--tel .tel {
  font-size: calc-fz(20);
}
.gnav--tel .num {
  font-size: calc-fz(24);
}
.gnav--tel .hours {
  font-size: calc-fz(14);
}
.gnav--contact a {
  padding: rem(24);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
}
.gnav--form {
  fieldset {
    display: grid;
    grid-template-columns: minmax(0, 1fr) rem(46);
    align-items: center;
    height: rem(35);
    background-color: var(--clr-text-light);
    border-radius: 100px;
    @include mq(1440) {
      height: rem(45);
    }
  }
  input {
    height: 100%;
    font-family: var(--font-primary);
    font-size: rem(15);
    background-color: var(--clr-text-light);
    padding: rem(3) rem(18);
    border-radius: 100px;
    border: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #b1b1b1;
    }
    @include mq(1440) {
      font-size: rem(18);
    }
    @include mq(1440) {
      font-size: rem(18);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
  button {
    @include center-flex;
    width: rem(48);
    height: 100%;
    cursor: pointer;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    transition: var(--transit-default);
    @include mq(med) {
      width: sprem(28);
    }
    &:hover {
      opacity: 0.7;
    }
    @include mq(sp) {
      padding: 0;
      width: sprem(14);
      img {
        width: 100% !important;
      }
    }
  }
}
.gnav--gt {
  height: fit-content !important;
  margin-left: rem(8);
}
.gnav--member {
  height: fit-content !important;
  margin-left: auto;
}
.gnav--member .gnav--link {
  font-family: var(--font-primary);
  font-size: rem(14);
  line-height: 1.7;
  color: var(--clr-primary-dark4);
  padding: rem(8) rem(19);
  border: 1px solid;
  border-radius: 100px;
  span {
    letter-spacing: 0.01em;
  }
  &::before {
    content: "";
    width: rem(24);
    height: rem(24);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-login.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--clr-primary-dark4);
    margin-right: rem(8);
    transition: var(--transit-default);
  }
  &:hover {
    background-color: var(--clr-primary-dark4);
    color: var(--clr-wht);
    &::before {
      background-color: var(--clr-wht);
    }
  }
  @include mq(1440) {
    font-size: rem(18);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(15);
  }
}

// gnav_btn-pc
.gnav_btn-pc {
  opacity: 1;
  pointer-events: auto;
  position: fixed;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/gnav-menu.png);
  width: rem(178);
  height: rem(178);
  right: vw(4);
  top: vw(4);
  cursor: pointer;
  pointer-events: auto;
  z-index: z(top);
  background-repeat: no-repeat;
  background-size: 100%;
  @include mq(1920) {
    right: vw(1);
    top: vw(1);
  }
  @include mq(1600) {
    right: 0;
    top: 0;
  }
}
// gnav_btn-sp
.gnav_btn-sp {
  position: absolute;
  top: 0;
  right: 0;
  width: sprem(88);
  height: sprem(88);
  .gnav_btn--lines span {
    background-color: var(--clr-primary-default);
  }
  .gnav_btn--txt {
    color: var(--clr-primary-default);
  }
}
.gnav_btn--hmb {
  position: absolute;
  top: rem(46);
  right: rem(40);
  line-height: 1.33;
  transition: var(--transit-default);
  span {
    font-size: rem(18);
    letter-spacing: 0.04em;
    color: var(--clr-wht, #fff);
  }
  @include mq(sp) {
    top: sprem(26);
    right: sprem(26);
  }
}
.gnav_btn--hmb-close {
  opacity: 0;
  pointer-events: none;
}
.gnav_btn--hmb-close.close {
  opacity: 1;
}
.gnav_btn--hmb-open.open {
  opacity: 1;
}
.gnav_btn--txt {
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: 0.01em;
  color: var(--clr-wht);
  padding-top: rem(9);
}

/* hmb
********************************************** */
.hmb {
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  transition: var(--transit-default);
  pointer-events: none;
  @include mq(sp) {
    height: 100%;
  }
}
.hmb.hmb_btn {
  opacity: 1;
  pointer-events: auto;
  background-color: rgb(176 224 246 / 50%);
  .hmb--wrap {
    pointer-events: auto;
    @include mq(sp) {
      width: 100% !important;
      height: 100%;
      border-radius: 0;
    }
  }
}
.hmb--wrap {
  position: relative;
  width: rem(940);
  height: 95vh;
  border-radius: 0 0 0 rem(72);
  margin-left: auto;
  pointer-events: auto;
  background-color: var(--clr-wht);
  pointer-events: none;
  @include mq(1683) {
    width: rem(1020);
  }
  @include mq(1419) {
    width: rem(1120);
  }
}
.hmb--inner {
  height: 95vh;
  margin-right: rem(32);
  padding: vw(123) vw(120) vw(66) vw(100);
  overflow-y: scroll;
  @include mq(sp) {
    height: 100%;
    margin-right: 0;
    padding: sprem(40) sprem(40);
  }
}
.hmb--logo img {
  width: rem(315) !important;
  @include mq(1683) {
    width: rem(380) !important;
  }
}

.hmb--gnav-container {
  width: rem(240);
  @include mq(1683) {
    margin-right: rem(40);
  }
  @include mq(1440) {
    width: rem(280);
  }
  @include mq(sp) {
    margin-right: 0;
  }
}
.hmb--gnav-container .gt_selector {
  width: sprem(50);
  padding-block: sprem(8);
}
.hmb--gnav-container .gnav--member {
  width: 100%;
  text-align: center;
  margin-left: 0;
  margin-bottom: rem(4);
  .gnav--link {
    padding: rem(5) rem(19);
    span {
      margin-top: 1px;
    }
    @include mq(sp) {
      padding: sprem(6) sprem(19);
    }
  }
  @include mq(sp) {
    margin-bottom: 0;
  }
}
.hmb--gnav-inner {
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.hmb--gnav-container .gnav--form {
  fieldset {
    height: rem(36);
    @include mq(1440) {
      height: rem(46);
    }
    @include mq(sp) {
      height: sprem(38);
    }
  }
  input {
    padding-left: rem(25);
    padding-right: rem(44);
  }
}
.hmb--other {
  margin-block: rem(55) rem(33);
  fieldset {
    width: rem(320);
    height: rem(36);
  }
  input {
    padding-left: rem(24);
  }
  button {
    padding-right: rem(24);
  }
  .gnav--member {
    margin-left: rem(16);
    margin-right: auto;
    .gnav--link {
      padding-block: rem(6) rem(4);
    }
  }
}
.hmb--menu--wrap {
  gap: vw(16);
  .hmb--menu:last-child {
    margin-right: rem(36);
    @include mq(sp) {
      margin-right: 0;
    }
  }
}
.hmb--menu .gnav--link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: var(--font-primary600);
  line-height: 2;
  text-align: left;
  color: #2e9ad4;
  padding-inline: 0;
  span {
    letter-spacing: 0;
  }
  &::before {
    content: "";
    display: inline-block;
    width: rem(6);
    height: rem(7);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/hmb-deco01.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: rem(16);
    @include mq(sp) {
      width: sprem(6);
      height: sprem(7);
    }
  }
  &:hover {
    opacity: 0.6;
  }
}
.hmb_sub_nav--wrap {
  padding-bottom: rem(8);
  @include mq(sp) {
    padding-bottom: sprem(4);
  }
}
.hmb_sub_nav--wrap .gnav--link {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: var(--font-primary);
  line-height: 2.2;
  color: var(--clr-text-primary);
  span {
    letter-spacing: 0.03em;
  }
  &::before {
    width: rem(16);
    height: auto;
    background-image: none;
    border-top: 1px solid #d2d2d2;
  }
}
.hmb--menu > .menu-item-has-children > .gnav--link {
  padding-bottom: rem(6);
  color: #2e9ad4;
  @include mq(sp) {
    padding-bottom: sprem(3);
  }
}
.hmb--menu > .menu-item:not(.menu-item-has-children) .gnav--link {
  padding-bottom: rem(16);
  @include mq(sp) {
    padding-bottom: sprem(8);
  }
}
.hmb--box {
  width: 49%;
  border-radius: rem(10);
  padding: rem(25) rem(20) rem(18);
}
.hmb--box a {
  width: 100%;
  height: vw(64);
  font-size: rem(16);
  line-height: 1.1;
  letter-spacing: 0;
  border-radius: 100px;
  margin-inline: auto;
  padding-block: rem(23) rem(24);
  &::before {
    content: "";
    display: inline-block;
    mask-repeat: no-repeat;
    mask-size: 100%;
    transition: var(--transit-default);
  }
  @include mq(1440) {
    height: vw(74);
    font-size: rem(18);
  }
  @include mq(pc) {
    height: vw(84);
    font-size: rem(21);
  }
  @include mq(sp) {
    width: 80% !important;
    font-size: sprem(16);
    padding-block: sprem(8);
  }
}
.hmb--box--ttl {
  font-family: var(--font-primary600);
  font-size: rem(18);
  line-height: 1.6;
  letter-spacing: 0;
  @include mq(1440) {
    font-size: rem(20);
  }
  @include mq(pc) {
    font-size: rem(22);
  }
}
.hmb--box--txt {
  font-size: rem(16);
  line-height: 2;
  letter-spacing: 0;
  padding-block: rem(18);
  @include mq(1440) {
    font-size: rem(18);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(13);
    line-height: 1.8;
    padding-block: sprem(16);
  }
}
.hmb--box.join {
  background-color: #f5f5f5;
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.hmb--box.join a {
  color: var(--clr-wht);
  border: 3px solid var(--clr-secondary-default);
  background-color: var(--clr-secondary-default);
  padding-right: rem(40);
  padding-left: rem(18);
  &::before {
    width: rem(33);
    height: rem(32);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-join.svg);
    background-color: var(--clr-wht);
    margin-right: vw(14);
    @include mq(sp) {
      margin-right: sprem(7);
    }
  }
  &:hover {
    color: var(--clr-secondary-default);
    background-color: var(--clr-wht);
    &::before {
      background-color: var(--clr-secondary-default);
    }
  }
  @include mq(sp) {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: sprem(20);
    padding-left: sprem(9);
  }
}
.hmb--box.cta {
  background-color: var(--clr-primary-light2);
  .hmb--box--ttl {
    color: var(--clr-primary-default);
  }
}
.hmb--box.cta a {
  color: var(--clr-primary-default);
  border: 3px solid var(--clr-primary-default);
  background: var(--clr-wht);
  box-shadow: 0 0 rem(32) 0 rgba(0, 0, 0, 0.05);
  padding-right: rem(40);
  padding-left: rem(24);
  &::before {
    width: rem(20);
    height: rem(17);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-mail.svg);
    background-color: var(--clr-primary-default);
    margin-right: vw(18);
    @include mq(sp) {
      margin-right: sprem(9);
    }
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    &::before {
      background-color: var(--clr-wht);
    }
  }
  @include mq(sp) {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.8;
    padding-right: sprem(24);
    padding-left: sprem(14);
  }
}
