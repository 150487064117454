@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  opacity: 1;
  position: relative;
  &:not([class*="mgn-"]) {
    margin-top: rem(-82);
  }
  z-index: 10;
  transition: opacity 0.5s ease;
  @include mq(sp) {
    overflow: auto;
    margin-top: 0;
  }
}
.anchor_link--list_wrap .anchor_link--list {
  max-width: vw(1440);
  background-color: var(--clr-primary-default);
  padding: rem(32) rem(100);
  @include mq(sp) {
    flex-wrap: inherit;
    white-space: nowrap;
    width: 100%;
    margin-inline: calc(50% - 50vw);
    padding-inline: 7%;
    overflow: auto;
  }
  @include mq(sp) {
    justify-content: left;
    max-width: rem(1440);
    border-radius: 0 !important;
    padding-block: 0;
  }
}
.anchor_link--list li {
  min-height: rem(64);
  @include mq(sp) {
    width: auto;
    min-height: sprem(64);
    &:first-child {
      margin-left: sprem(-6);
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.anchor_link--list a {
  position: relative;
  width: fit-content;
  height: 100%;
  font-family: var(--font-secondary);
  font-size: rem(18);
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--clr-wht);
  padding: 0.5em 2.5em 0.5em 2em;
  margin-inline: auto;
  @include mq(1440) {
    font-size: rem(20);
  }
  @include mq(pc) {
    font-size: rem(22);
  }
  @include mq(sp) {
    font-size: sprem(14);
    padding: sprem(14) sprem(24) sprem(14) sprem(16);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: rem(24);
    transform: translateY(-50%);
    @include rect(8, 6);
    background-color: var(--clr-wht);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transition: var(--transit-default);
    margin-left: rem(10);
    @include mq(sp) {
      right: sprem(10);
    }
  }
  &:hover {
    opacity: 0.5;
  }
}

/* .anchor_link--list_fixed
********************************************** */
.anchor_link--list_fixed {
  position: fixed;
  bottom: rem(40);
  right: rem(40);
  border-radius: rem(48);
  transition: opacity 0.5s ease;
  opacity: 0;
  z-index: 9989;
  .anchor_link--list {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--clr-primary-default);
    border-radius: 0 rem(32) rem(32) 0;
    padding: rem(22) rem(40) rem(22) 0;
    margin-inline: 0;
    li {
      min-height: auto;
      display: inline-block;
    }
    a {
      width: 100%;
      display: block;
      font-size: rem(17);
      padding: rem(10) rem(28) rem(10) 0;
      white-space: nowrap;
      &::before {
        right: vw(8);
      }
      @include mq(1440) {
        font-size: rem(19);
      }
      @include mq(pc) {
        font-size: rem(22);
      }
    }
  }
  @include mq(sp) {
    display: none;
  }
}

// anchor_link--btn
.anchor_link--btn {
  position: relative;
  @include write-v;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.7;
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  padding: rem(32) rem(24) rem(30);
  border-radius: rem(32);
  cursor: pointer;
  p {
    transition: var(--transit-default);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      color: rgb(255 255 255 / 50%);
      + .open::before,
      + .open::after {
        background-color: rgb(255 255 255 / 50%);
      }
    }
  }
  .open {
    position: relative;
    display: block;
    @include rect(16);
    transition: var(--transit-default);
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: rem(16);
      height: rem(2);
      background-color: var(--clr-wht);
      border-radius: 100px;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
  @include mq(1440) {
    font-size: rem(16);
  }
  @include mq(pc) {
    font-size: rem(18);
  }
}
.anchor_link--btn.selected {
  border-radius: rem(32) 0 0 rem(32);
}
.anchor_link--btn.selected .open::before {
  transform: rotate(-45deg);
}
.anchor_link--btn.selected .open::after {
  transform: rotate(45deg);
}
