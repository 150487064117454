@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */

@keyframes translate {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
