@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(25) rem(80);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(245), 100%);
  font-family: var(--font-secondary);
  font-size: rem(18);
  line-height: normal;
  letter-spacing: -0.07em;
  color: var(--clr-primary-default);
  border: 2px solid var(--clr-primary-default);
  text-align: center;
  text-decoration: none;
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    min-width: 100%;
  }
  &:hover:not(.btn2 a) {
    background-color: var(--clr-bg-02);
  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
  @include mq(sp) {
    font-size: sprem(16);
    padding: sprem(16) sprem(48);
  }
}
.mce-content-body .btn a {
  margin-inline: 0 !important;
}
.btn.btn2 a {
  font-family: var(--font-primary);
  font-size: rem(17);
  line-height: 2.4;
  letter-spacing: 0.03em;
  color: var(--clr-text-primary);
  border: none;
  border-radius: 0;
  padding-left: rem(89);
  text-align: left;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: rem(64);
    height: rem(64);
    background-color: var(--clr-primary-dark);
    border: 1px solid var(--clr-primary-dark);
    border-radius: 100%;
    transition: var(--transit-default);
    @include mq(sp) {
      width: sprem(40);
      height: sprem(40);
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: rem(24);
    bottom: 0;
    margin: auto;
    width: rem(16);
    height: rem(15);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/arrow_btn2.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--clr-wht);
    transition: var(--transit-default);
    @include mq(sp) {
      left: sprem(14);
    }
  }
  &:hover {
    &::before {
      background-color: var(--clr-wht);
    }
    &::after {
      background-color: var(--clr-primary-dark);
    }
  }
  svg {
    @include mq(sp) {
      right: 0;
    }
  }
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    width: fit-content;
    min-width: auto !important;
    font-size: sprem(15);
    margin-inline: auto;
    padding-right: sprem(32);
    padding-left: sprem(56);
  }
}
.btn .no_link {
  cursor: auto !important;
  background-color: var(--clr-bg-02);
}
.btn.btn-ctr a {
  @include auto-margin;
}
.btn-outside a::after,
.btn-outside a .deco::after {
  content: "";
  display: inline-block;
  @include rect(12);
  mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-new_tab.svg);
  mask-repeat: no-repeat;
  mask-size: 100%;
  background-color: var(--clr-primary-default);
  margin-left: vw(8);
}
.btn-outside a .deco::after {
  background-color: var(--clr-text-primary);
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-primary-default);
  background-color: var(--clr-wht);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
  svg {
    fill: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--clr-secondary-default);
  &:hover {
    background-color: var(--clr-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}

/* --- single--btn --- */
.single--btn {
  padding-block: vw(80) vw(139);
  a {
    width: vw(890);
    height: vw(76);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-secondary);
    font-size: rem(18);
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.07em;
    color: var(--clr-primary-dark);
    border: 2px solid var(--clr-primary-dark);
    background-color: var(--clr-wht);
    border-radius: 100px;
    margin-inline: auto;
    &:hover {
      color: var(--clr-wht);
      background-color: var(--clr-primary-dark);
    }
    @include mq(1440) {
      font-size: rem(20);
    }
    @include mq(pc) {
      font-size: rem(22);
    }
    @include mq(sp) {
      width: 90%;
      height: fit-content;
      font-size: sprem(16);
      padding-block: sprem(16);
    }
  }
  @include mq(sp) {
    padding-block: sprem(40) sprem(70);
  }
}
