@use "../abstracts" as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body.wp-editor {
  padding: 24px !important;
}
.editor-styles-wrapper,
.mce-content-body {
  &::before,
  &::after {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
  }
}

.editor-styles-wrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-jp);
    &:not(:first-child) {
      margin-top: 2em;
      @include mq(sp) {
        margin-top: 1.5em;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1em;
      @include mq(sp) {
        margin-bottom: 0.75em;
      }
    }
  }
  h1,
  h2 {
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(26);
    }
  }
  h3 {
    font-size: calc-fz(26);
    @include mq(sp) {
      font-size: sprem(22);
    }
  }
  h4 {
    font-size: calc-fz(20);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
  h5 {
    font-size: calc-fz(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  h6 {
    font-size: calc-fz(17);
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
}

.editor-styles-wrapper ol,
.mce-content-body ol {
  list-style: decimal;
}
.editor-styles-wrapper ul,
.editor-styles-wrapper ol,
.mce-content-body ul,
.mce-content-body ol {
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.editor-styles-wrapper ul li,
.editor-styles-wrapper ol li,
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin-left: perc(40, 16, em) !important;
  padding-top: 0.35em;
  &::marker {
    font-family: var(--font-num);
    color: var(--clr-primary-dark2);
    font-size: rem(17);
    letter-spacing: -0.04em;
    @include mq(1440) {
      font-size: rem(19);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
  @include mq(sp) {
    margin-left: perc(32, 16, em) !important;
  }
}
.mce-content-body ol {
  padding-left: 0;
}
.mce-content-body ol li {
 list-style: none; 
 padding-left: 2em;
 margin-left: 0 !important;
 @include mq(sp) {
  padding-left: 2.5em;
 }
}
.mce-content-body ol li::before {
content: '';
position: absolute;
left: .5em;
top: .525em;
@include mq(sp) {
  top: .125em;
}
display: inline-block;

  font-family: var(--font-num);
  color: var(--clr-primary-dark2);
  font-size: rem(17);
  letter-spacing: -0.04em;
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.mce-content-body > ol {
  counter-reset: section;
}

.mce-content-body > ol > li {
 
  counter-increment: section;
  &::before {
    content: counter(section, decimal-leading-zero)".";
  }
}
.mce-content-body > ol > li > ol {
 counter-reset: subsection; 
}
.mce-content-body > ol > li > ol > li {
 padding-left: 0;
 display: grid;
 grid-template-columns: auto 1fr;
 column-gap: .5em;
 @include mq(sp) {
   padding-left: 0;
 }
 counter-increment: subsection; 
 &::before {
 position: relative;
 top: initial;
 left: initial;
 content: counter(section, decimal-leading-zero) "-" counter(subsection) " ";
 display: inline-block;
 }

}

.editor-styles-wrapper ul li,
.mce-content-body ul li {
  list-style: none;
  &::before {
    content: "";
    position: absolute;
    top: perc(17, 16, em);
    left: perc(-34, 16, em);
    width: perc(10, 16, em);
    aspect-ratio: 1/1;
    border: 1px solid var(--clr-primary-default);
    border-radius: 50%;
    background-color: var(--clr-primary-default);
  }
}
.editor-styles-wrapper ul[style="list-style-type: circle;"] li::before,
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--clr-wht);
}
.editor-styles-wrapper ol,
.mce-content-body ol {
  list-style-type: decimal-leading-zero;
}
.editor-styles-wrapper .wp-block-image img,
.mce-content-body .wp-block-image img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.editor-styles-wrapper .wp-caption,
.mce-content-body .wp-caption {
  width: fit-content;
  max-width: 100%;
}
.editor-styles-wrapper hr,
.mce-content-body hr {
  margin-block: rem(32);
  border: none;
  border-bottom: 1px solid var(--clr-text-placeholder);
  @include mq(sp) {
    margin-block: 0;
  }
}
.editor-styles-wrapper a .wp-block-image img,
.mce-content-body a .wp-block-image img {
  transition: var(--transit-default);
}
.editor-styles-wrapper a:hover .wp-block-image img,
.mce-content-body a:hover .wp-block-image img {
  opacity: 0.8;
}
.editor-styles-wrapper .alignleft,
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.editor-styles-wrapper .alignright,
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.editor-styles-wrapper .aligncenter,
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.editor-styles-wrapper .wp-block-image img.aligncenter,
.mce-content-body .wp-block-image img.aligncenter {
  display: block;
}
.editor-styles-wrapper .alignleft:last-child,
.editor-styles-wrapper .alignright:last-child,
.editor-styles-wrapper .aligncenter:last-child,
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.editor-styles-wrapper p:not(:last-child),
.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}
.editor-styles-wrapper strong,
.mce-content-body strong {
  font-weight: bold;
}
.editor-styles-wrapper blockquote,
.mce-content-body blockquote {
  margin: 1em 0;
  padding: rem(16) rem(40);
  border-radius: rem(3);
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--clr-bg-02);
  text-align: left;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 1em;
    height: 1em;
    opacity: 0.25;
  }
  &::before {
    background-image: url("../images/common/icon-quote_left.svg");
    top: rem(14);
    left: rem(12);
  }
  &::after {
    background-image: url("../images/common/icon-quote_right.svg");
    bottom: rem(14);
    right: rem(12);
  }
}
.editor-styles-wrapper blockquote p,
.mce-content-body blockquote p {
  margin-bottom: 0;
}
.editor-styles-wrapper a,
.mce-content-body a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.editor-styles-wrapper table,
.mce-content-body table {
  border: none;
  height: auto !important;
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.editor-styles-wrapper th,
.editor-styles-wrapper td,
.editor-styles-wrapper tr,
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.editor-styles-wrapper th,
.editor-styles-wrapper td,
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid var(--clr-primary-dark);
  vertical-align: top;
}
.editor-styles-wrapper th,
.mce-content-body th {
  min-width: rem(120);
  text-align: left;
}
.editor-styles-wrapper sup,
.mce-content-body sup {
  font-size: calc-fz(12);
  color: #555;
}
.bold {
  font-family: var(--font-primary700);
  color: var(--clr-primary-dark);
}

/* iframe */
.editor-styles-wrapper iframe,
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}

@include mq(sp) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .editor-styles-wrapper blockquote,
  .mce-content-body blockquote {
    padding: rem(16);
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
  }
  .editor-styles-wrapper table,
  .mce-content-body table {
    max-width: none !important;
  }
  .editor-styles-wrapper th,
  .mce-content-body th {
    font-weight: bold;
  }
  .editor-styles-wrapper th,
  .editor-styles-wrapper td,
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }

  /* table scroll */
  .table-sp-scroll {
    width: sprem(700);
    @include mq(sp) {
      white-space: nowrap;
    }
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table {
    margin: 0;
  }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: 0.25;
    font-size: calc-fz(14);
  }
}

/* ブロックスタイル
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3,
.is-style-editor-ttl,
.is-style-editor-ttl2,
.is-style-editor-ttl3 {
  font-family: var(--font-editor-ttl) !important;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.03em;
  &:not(:first-child) {
    margin-top: 2em;
    @include mq(sp) {
      margin-top: 1.5em;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1em;
    @include mq(sp) {
      margin-bottom: 0.75em;
    }
  }
}
.editor-ttl,
.is-style-editor-ttl {
  font-size: rem(34);
  @include mq(1440) {
    font-size: rem(36);
  }
  @include mq(pc) {
    font-size: rem(38);
  }
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.editor-ttl2,
.is-style-editor-ttl2 {
  font-size: rem(26);
  @include mq(1440) {
    font-size: rem(28);
  }
  @include mq(pc) {
    font-size: rem(30);
  }
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.editor-ttl3,
.is-style-editor-ttl3 {
  font-size: rem(22);
  color: var(--clr-primary-dark);
  @include mq(1440) {
    font-size: rem(24);
  }
  @include mq(pc) {
    font-size: rem(26);
  }
  @include mq(sp) {
    font-size: sprem(16);
  }
}

/* 注意書き */
.txt-attention,
.is-style-txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: calc-fz(14);
  opacity: 0.6;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: "※";
  }
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - rem(3));
  }
}
.table1 th {
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -0.5px;
    width: 100%;
    height: 2px;
    border-radius: 100px;
    background-color: var(--clr-primary-dark);
  }
}
.table1 td::before {
  right: 0;
  border-bottom: 1px solid #d3d3d3;
}
.table1 th,
.table2 th {
  font-family: var(--font-primary700);
  color: var(--clr-primary-dark) !important;
}
.table2 th {
  background-color: var(--clr-bg-02);
}
.table2 td:not(:last-child) {
  border-right: 1px solid var(--clr-primary-light4);
}
.table2 {
  position: relative;
  -webkit-clip-path: inset(0 round rem(10));
  clip-path: inset(0 round rem(10));
  width: 100%;
  border-collapse: separate;
  border-radius: rem(10);
  border: 1px solid var(--clr-primary-dark) !important;
  overflow: hidden;
  th,
  td {
    border: none;
    vertical-align: middle;
    @include mq(sp) {
      padding: 1em;
    }
  }
  tr:not(:last-of-type):not(:last-of-type) th {
    border-bottom: 1px solid var(--clr-primary-light4);
  }
  tr:not(:last-of-type) td {
    border-bottom: 1px solid var(--clr-primary-light4);
  }
  @include mq(sp) {
    -webkit-clip-path: inset(0 round sprem(5));
    clip-path: inset(0 round sprem(5));
    border-radius: sprem(5);
  }
  @include mq(sp) {
    -webkit-clip-path: inset(0 round sprem(16));
    clip-path: inset(0 round sprem(16));
    border-radius: sprem(16);
  }
}

/* ボックス */
.box,
.is-style-box {
  padding: rem(30);
  background-color: var(--clr-bg-01);
  border-radius: rem(10);
  @include mq(sp) {
    padding: rem(24);
  }
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  *:not([class*="clr-"]):not([class*="bold"]):not([class*="editor-ttl"]) {
    color: var(--clr-text-primary);
  }
}
.lps_sec:nth-child(even),
.lps_sec[style*="background-color"] {
  *:not(.bg-wht) .box {
    background-color: var(--clr-wht);
  }
}

/* リスト */
.list-circle::before,
.editor-styles-wrapper ul .is-style-list-circle::before {
  background-color: transparent;
}
.list-square::before,
.editor-styles-wrapper ul .is-style-list-square::before {
  border-radius: 0;
}
ul.check--list li,
.list-check,
.editor-styles-wrapper ul .is-style-list-check {
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    top: perc(10, 16, em);
    left: perc(-40, 16, em);
    width: perc(24, 16, em);
    aspect-ratio: 1/1;
    background-color: var(--clr-primary-default);
    border-radius: 50%;
  }
}
.list-check::after,
.editor-styles-wrapper ul .is-style-list-check::after {
  top: perc(17, 16, em);
  left: perc(-33, 16, em);
  width: perc(10, 16, em);
  height: perc(7, 16, em);
  border-left: 2px solid var(--clr-wht);
  border-bottom: 2px solid var(--clr-wht);
  transform: rotate(-45deg);
}
ul.check--list li {
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    top: perc(10, 16, em);
    left: perc(-40, 16, em);
    width: perc(24, 16, em);
    aspect-ratio: 1/1;
    background-color: var(--clr-primary-dark2);
    border-radius: 50%;
  }
  &::after {
    top: perc(18, 16, em);
    left: perc(-34, 16, em);
    width: perc(13, 16, em);
    height: perc(9, 16, em);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/check.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

@include mq(sp) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
}

/* 管理画面エディタ用
*************************************************** */
h1.editor-post-title__input {
  padding: 32px 0;
  font-size: rem(40);
  font-family: var(--font-jp);
}

@include mq(sp) {
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2),
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}

/* コアブロック
********************************************** */
.has-text-align-left {
  text-align: left;
}
.has-text-align-center {
  text-align: center;
}
.has-text-align-right {
  text-align: right;
}
.em {
  font-style: italic;
}

/* デフォルトの機能
********************************************** */
.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table.aligncenter table,
.wp-block-table.alignleft table,
.wp-block-table.alignright table {
  display: table;
  width: auto;
}
.wp-block[data-align="center"] > .wp-block-table table {
  margin: 0 auto;
}
