@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */
.footer .inner {
  gap: vw(80);
  @include mq(1440) {
    max-width: vw(1600);
  }
}
.subpage .footer_cta {
  border-top: 1px solid var(--clr-text-placeholder2);
}
.template-contact-simple .footer {
  padding-top: rem(180);
  background-color: var(--clr-bg-01);
  .footer_top {
    margin-inline: 0;
  }
  @include mq(sp) {
    padding-top: sprem(24);
  }
}

/* footer_cta
********************************************** */
@include mq(sp) {
  .footer_cta {
    margin-inline: sprem(16);
  }
}
.cta-box,
.join-box {
  position: relative;
  background-color: var(--clr-wht);
  transition: var(--transit-default);
  padding-block: vw(155) vw(302);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0;
    transition: var(--transit-default);
  }
  &:hover::before {
    opacity: 1;
  }
  @include mq(sp) {
    padding-block: sprem(56);
  }
}
.cta-box {
  border-right: 1px solid var(--clr-text-placeholder2);
  &::before {
    background-image: url("/wp/wp-content/themes/standard_sass/images/cta-img02.png");
    @include mq(sp) {
      background-image: none;
    }
  }
  @include mq(sp) {
    border-right: none;
    border-bottom: 1px solid var(--clr-text-placeholder2);
  }
}
.join-box::before {
  background-image: url("/wp/wp-content/themes/standard_sass/images/cta-img01.png");
  @include mq(sp) {
    background-image: none;
  }
}
.cta-box .txt,
.join-box .txt {
  font-size: rem(17);
  padding-block: vw(51) vw(48);
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(14);
    padding-block: sprem(26) sprem(24);
  }
}
.footer_cta--btn a {
  font-family: var(--font-primary600);
  font-size: rem(18);
  line-height: 1.1;
  border-radius: 100px;
  &::before {
    content: "";
    display: inline-block;
    mask-repeat: no-repeat;
    mask-size: 100%;
    transition: var(--transit-default);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  @include mq(1440) {
    font-size: rem(20);
  }
  @include mq(pc) {
    font-size: rem(22);
  }
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.cta-box .footer_cta--btn a,
.join-box .footer_cta--btn a {
  height: vw(64);
  margin-inline: auto;
  @include mq(sp) {
    position: relative;
    height: sprem(52);
  }
}
.cta-box .footer_cta--btn a {
  width: fit-content;
  color: var(--clr-primary-default);
  letter-spacing: 0;
  background-color: var(--clr-wht);
  border: 2px solid var(--clr-primary-default);
  padding-right: vw(35);
  padding-left: vw(33);
  &::before {
    width: rem(23);
    height: rem(20);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-mail.svg);
    background-color: var(--clr-primary-default);
    margin-right: vw(26);
    @include mq(sp) {
      margin-right: sprem(13);
    }
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    &::before {
      background-color: var(--clr-wht);
    }
  }
  @include mq(sp) {
    padding-right: sprem(22);
    padding-left: sprem(21);
  }
}
.join-box .footer_cta--btn a {
  width: fit-content;
  color: var(--clr-wht);
  letter-spacing: 0.05em;
  background-color: var(--clr-secondary-default);
  border: 2px solid var(--clr-secondary-default);
  padding-right: vw(53);
  padding-left: vw(19);
  &::before {
    width: rem(32);
    height: rem(32);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-join.svg);
    background-color: var(--clr-wht);
    margin-right: vw(24);
    @include mq(sp) {
      margin-right: sprem(12);
    }
  }
  &:hover {
    color: var(--clr-secondary-default);
    background-color: var(--clr-wht);
    &::before {
      background-color: var(--clr-secondary-default);
    }
  }
  @include mq(sp) {
    padding-right: sprem(40);
    padding-left: sprem(28);
  }
}

/* footer
*************************************************** */
.footer--logo a {
  background-color: var(--clr-wht);
  padding: rem(8) rem(72) rem(10) rem(16);
  border-radius: rem(20);
  img {
    width: rem(429) !important;
  }
  @include mq(sp) {
    padding: sprem(4) sprem(36) sprem(5) sprem(8);
    border-radius: sprem(10);
  }
}
.footer--address {
  font-family: var(--font-primary);
  font-size: rem(17);
  line-height: 1.8;
  padding-bottom: rem(71);
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(14);
    padding-top: sprem(16);
    padding-bottom: sprem(32);
  }
}
.footer--mail {
  width: fit-content;
  display: block;
  color: var(--clr-wht);
  span {
    text-decoration: underline;
  }
  &:hover {
    opacity: 0.7;
  }
}
.footer--relation {
  width: fit-content;
  @include mq(sp) {
    padding-top: sprem(16);
  }
}
.relation--ttl {
  line-height: 2;
  color: var(--clr-wht);
  background-color: #087ac5;
  border-radius: 100px;
  margin-right: rem(4);
  @include mq(sp) {
    margin-right: 0;
  }
}
.relation--wrap {
  margin-right: rem(4);
  @include mq(sp) {
    margin-right: 0;
  }
}
.relation--wrap a {
  display: flex;
  align-items: center;
  background-color: var(--clr-wht);
  border-radius: rem(6);
  &:first-child {
    padding: rem(1) rem(3) rem(2);
  }
  &:nth-child(2) {
    padding: rem(5) rem(5) rem(4) rem(6);
    margin-inline: rem(4);
  }
  &:last-child {
    padding: rem(3) rem(9) rem(6) rem(6);
  }
  &:hover {
    opacity: 0.7;
  }
}
.fnav .fnav--menu:nth-child(2) {
  margin-inline: rem(100);
  @include mq(sp) {
    margin-inline: 0;
  }
}

/* footer_top
********************************************** */
.footer_top {
  position: relative;
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  border-radius: rem(100) rem(100) 0 0;
  margin-top: vw(-104);
  margin-inline: rem(16);
  padding-top: rem(150);
  @include mq(sp) {
    border-radius: sprem(50) sprem(50) 0 0;
    margin-top: 0;
    margin-inline: sprem(8);
    padding-top: sprem(56);
  }
}
/* ---fnav --- */
.fnav {
  padding-bottom: rem(148);
  @include mq(sp) {
    padding-bottom: sprem(64);
  }
}
.fnav--wrap {
  padding-top: rem(38);
  @include mq(sp) {
    padding-top: 0;
  }
}
.footer_sub_nav--wrap {
  padding-left: rem(22);
}
.fnav--menu {
  @include mq(sp) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: sprem(10);
    column-gap: sprem(10);
  }
}
.fnav--menu a {
  display: block;
  font-size: rem(17);
  line-height: 2;
  color: var(--clr-wht);
  padding-block: vw(1);
  &:hover {
    opacity: 0.6;
  }
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(12);
    padding-block: sprem(1);
  }
}
.fnav--menu > .menu-item > a {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: var(--font-primary600);
  font-size: rem(18);
  color: var(--clr-wht);
  &::before {
    content: "";
    display: inline-block;
    width: rem(6);
    height: rem(7);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/hmb-deco01.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--clr-wht);
    margin-right: rem(16);
  }
  @include mq(1440) {
    font-size: rem(20);
  }
  @include mq(pc) {
    font-size: rem(22);
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.fnav--menu .sub-menu {
  font-size: calc-fz(15);
  line-height: 1;
  opacity: 0.75;
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(48);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  fill: var(--clr-wht);
  @include rect(28);
  &.icon-x {
    @include rect(24);
  }
}
.sns--txt {
  padding-right: rem(20);
  p {
    font-size: rem(20);
    font-weight: 600;
    line-height: 1.7;
    letter-spacing: -0.04em;
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding-block: rem(54);
  background-color: var(--clr-primary-default);
  @include mq(sp) {
    padding-block: sprem(56) sprem(40);
  }
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  color: var(--clr-wht);
  gap: rem(24);
  font-size: rem(14);
  font-weight: 600;
  line-height: 1;
  justify-content: center;
  opacity: 0.7;
  @include mq(sp) {
    text-align: center;
    gap: 0.8em;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  @include mq(sp) {
    width: fit-content;
    margin-inline: auto;
  }
}
