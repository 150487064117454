@use "../abstracts" as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* related_page
********************************************** */
.section-related_page {
  padding-block: rem(200);
  @include mq(sp) {
    padding-block: sprem(56);
  }
}
.related_page--list {
  position: relative;
  border-radius: rem(40);
  overflow: hidden;
  z-index: 1;
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
  }
  @include mq(sp) {
    display: flex;
    flex-direction: column;
    gap: sprem(8);
    border-radius: 0;
  }
}
.related_page--list a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(115) rem(64);
  line-height: var(--line-height-hdr);
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  @include mq(sp, min, ps) {
    height: rem(320);
  }
  @include mq(sp) {
    border-radius: sprem(10);
    padding: sprem(32) sprem(32) sprem(32) sprem(24);
  }
  &:hover {
    background-color: rgba(38, 156, 234, 0.5);
    .related_page--bg {
      opacity: 1;
      @include mq(sp) {
        opacity: 0;
      }
    }
  }
}
.related_page--list .font-jp {
  line-height: 1.7;
  margin-bottom: 0.5em;
  @include mq(sp) {
    font-size: sprem(18);
    margin-bottom: 0.2em;
  }
}
.related_page--list .font-en {
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-num);
  font-size: rem(16);
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  @include mq(1440) {
    font-size: rem(18);
  }
  @include mq(pc) {
    font-size: rem(20);
  }
  @include mq(sp) {
    font-size: sprem(10);
  }
}
.related_page--bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  z-index: -1;
  transition: var(--transit-default);
}
