@use "../abstracts" as *;

/* ##############################################################################

    SINGLE

############################################################################## */

/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: rem(32);
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}
div#ez-toc-container {
  padding: 0;
  border: none !important;
  border-radius: 0;
  box-shadow: none;
}
.ez-toc-title-container {
  margin-bottom: vw(32);
  @include mq(sp) {
    margin-bottom: 0;
  }
}
div#ez-toc-container p.ez-toc-title {
  font-family: var(--font-primary600);
  font-size: rem(24);
  line-height: 2;
  color: var(--clr-primary-dark) !important;
}
div#ez-toc-container .ez-toc-title_en {
  display: block;
  font-family: var(--font-en);
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.7;
  margin-top: vw(-6);
}
#ez-toc-container.counter-hierarchy li {
  margin-bottom: 1.25em;
}
#ez-toc-container.counter-hierarchy li a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: rem(17);
  line-height: 1.8;
  color: var(--clr-text-primary) !important;
  &::before {
    content: "";
    display: block;
    width: vw(16);
    height: 1px;
    background-color: #d2d2d2;
    margin-right: vw(16);
    @include mq(sp) {
      width: sprem(12);
      height: 1px;
      margin-right: sprem(10);
    }
  }
  &:hover {
    text-decoration: none;
    color: var(--clr-primary-dark) !important;
  }
  @include mq(1440) {
    font-size: rem(19);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
  @include mq(sp) {
    font-size: sprem(15);
  }
}

#ez-toc-container nav {
  @include mq(sp) {
    padding-top: sprem(14);
  }
} 
.ez-toggel_btn {
  position: absolute;
  top: rem(30);
  right: rem(20);
  z-index: 1;
  margin: auto;
  @include rect(56);
  color: var(--clr-wht);
  background-color: var(--clr-primary-dark2);
  border-radius: 50%;
  transition: var(--transit-default);
  display: none;
  @include mq(sp) {
    display: block;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: rem(12);
    height: 2px;
    border-bottom: 2px solid;
    transition: var(--transit-default);
  }
  &::after {
    transform: rotate(90deg);
  }
  &.active {
      transform: rotate(180deg);
      background-color: rgb(13 133 184 / 20%);
      &::before {
        border-bottom: 2px solid var(--clr-primary-dark3);
      }
      &::after {
        opacity: 0;
      }

  }
}

/* single-information
**************************************** */
.single-information .page_ttl-en {
  display: none;
}
.information--info--inner {
  width: vw(1072);
  margin-inline: auto;
  padding-block: vw(64);
  .editor-styles-wrapper > :first-child {
    margin-top: 0 !important;
  }
  @include mq(sp) {
    width: 86%;
    padding-block: sprem(28);
  }
}
.single-information .main_side {
  padding-top: vw(48);
  @include mq(sp) {
    padding-top: sprem(40);
    flex-direction: column-reverse !important;
  }
}
.information--download {
  background-color: var(--clr-wht);
  border-radius: rem(20);
  border: 11px solid var(--clr-bg-01);
  padding: vw(34) vw(24) vw(42);
  @include mq(sp) {
    border: 8px solid var(--clr-bg-01);
    padding: sprem(17) sprem(12) sprem(21);
  }
}
.download--ttl {
  color: var(--clr-primary-dark);
  font-family: var(--font-editor-ttl);
  font-size: rem(24);
  line-height: 1.5;
  letter-spacing: -0.03em;
  padding-bottom: vw(39);
  @include mq(sp) {
    font-size: sprem(18);
    padding-bottom: sprem(20);
  }
}

/* information--toc
**************************************** */
.information--toc {
  position: sticky;
  top: rem($header-hgt + 48);
  padding: vw(48);
  width: rem(480);
  max-width: 100%;
  @include mq(sp) {
    position: relative;
    top: auto;
    padding: sprem(18) sprem(20);
  }
}

/* news study statement
**************************************** */
.single-news .single--btn,
.single-study .single--btn,
.single-statement .single--btn {
  padding-bottom: vw(20);
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}
#pass input[name="post_password"] {
  padding: rem(5);
  border: solid 1px #aaa;
  outline: none;
}
#pass input[type="submit"] {
  padding: rem(4) rem(6);
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: rem(4);
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}
#pass input[type="submit"]:hover {
  opacity: 0.7;
}

/* share
**************************************** */
.share--list a {
  @include center-flex;
  height: rem(40);
  padding: 0.5em;
  line-height: 1;
  font-size: calc-fz(12);
  font-weight: bold;
  border: 1px solid #ddd;
}
.share--list li {
  &.x a {
    color: #000;
  }
  &.facebook a {
    color: #2477f2;
  }
  &.line a {
    color: #27c754;
  }
  &.pocket a {
    color: #ef4056;
  }
  &.linkedin a {
    color: #2867b2;
  }
  &.hatena a {
    color: #29a4de;
  }
}
.share--list img {
  margin-right: rem(10);
}
.share--list a:hover {
  opacity: 0.5;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc-clamp(20, $wid-var);
  white-space: nowrap;
  background-color: var(--clr-wht);
  margin-top: vw(53);
  margin-inline: auto;
  border-radius: 100px;
  padding: vw(10);
  @include mq(sp) {
    gap: 0;
    align-items: center;
    margin-top: sprem(32);
    padding: sprem(2) sprem(6);
  }
}
.wp-pagenavi > * {
  @include center-flex;
}
.wp-pagenavi .pages {
  display: none;
}
.wp-pagenavi .nextpostslink,
.wp-pagenavi .previouspostslink {
  background-color: var(--clr-primary-dark);
}
.wp-pagenavi .previouspostslink::before {
  transform: rotate(180deg);
}
.wp-pagenavi a {
  position: relative;
  padding: 1.5em;
  height: calc-clamp(64, $wid-var);
  border-radius: 100px;
  @include mq(sp) {
    height: sprem(40);
  }
  &:hover {
    opacity: 0.6;
  }
}
.wp-pagenavi .current {
  background-color: var(--clr-primary-light2);
  border-radius: 100px;
}
.wp-pagenavi .current,
.wp-pagenavi .page {
  font-family: "Chillax", sans-serif;
  font-size: rem(20);
  line-height: normal;
  letter-spacing: 0;
  color: var(--clr-primary-dark);
}
.archive .wp-pagenavi > *:not(.pages) {
  width: calc-clamp(64, $wid-var);
  aspect-ratio: 1/1;
  @include mq(sp) {
    width: sprem(40);
  }
}
.wp-pagenavi-single a[rel="prev"],
.wp-pagenavi-single a[rel="next"] {
  aspect-ratio: 1/1;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  width: rem(16);
  height: rem(15);
  margin: auto;
  mask-image: url(/wp/wp-content/themes/standard_sass/images/common/arrow_btn2.svg);
  mask-repeat: no-repeat;
  mask-size: 100%;
  background-color: var(--clr-wht);
}
.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-135deg);
}
.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: rem(-6);
}
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: rem(-6);
}
@include mq(sp) {
  .wp-pagenavi .last,
  .wp-pagenavi .first {
    display: none;
  }
}
