@use "../abstracts" as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
  }
}
body {
  font-family: var(--font-primary);
  line-height: 2;
  font-weight: 400;
  font-feature-settings: "palt";
  color: var(--clr-text-primary);
  height: 100%;
  padding-top: rem($header-hgt);
  word-break: break-word;
  @include mq(sp, min, ps) {
    // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
    font-size: calc-clamp($fz-pc, $wid-var) !important;
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: rem(1080);
      }
    }
  }
  @include mq(med) {
    padding-top: sprem($header-hgt-sp);
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
* {
  letter-spacing: var(--ltr-space-default);
}
ul,
ol {
  list-style: none;
}
small {
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(12);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
.flex_list--col2 li {
  width: 44%;
}

/* all_wrap
********************************************** */
.all_wrap {
  position: fixed;
  box-sizing: border-box;
  z-index: z(header);
  inset: 0;
  height: 100lvh;
  pointer-events: none;
  transform: translateZ(0.01px);
}
.frame {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background-repeat: no-repeat;
  z-index: z(frame);
  background-size: cover;
}
.frame-lft-top {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-lft-top.png);
  background-position: left top;
  z-index: 2;
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-lft-top_sp.png);
    z-index: 3;
  }
}
.frame-rgt-top {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-rgt-top.png);
  background-position: right top;
  z-index: 2;
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-rgt-top_sp.png);
  }
}
.frame-rgt-btm {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-rgt-btm.png);
  background-position: right bottom;
  z-index: 1;
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-rgt-btm_sp.png);
    z-index: 2;
  }
}
.frame-lft-btm {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-lft-btm.png);
  background-position: left bottom;
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/frame-lft-btm_sp.png);
  }
}

/* anchor_not
********************************************** */
.anchor_not .page_ttl:not(.page_ttl-has_bg) {
  .page_ttl--area,
  .breadcrumbs {
    padding-bottom: 0;
  }
}

/* GTranslate
********************************************** */
.gt_selector {
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  font-size: rem(14);
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/gt_arrow.svg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 5px 4px;
  background-color: #2294e7;
  border: none;
  border-radius: 100px;
  padding: rem(9) rem(16) rem(9) rem(12);
  &:focus-visible {
    outline: none;
  }
  @include mq(1440) {
    font-size: rem(18);
  }
  @include mq(pc) {
    font-size: rem(21);
  }
}
.gt_selector::-ms-expand {
  display: none;
}
.gtranslate_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #2294e7;
  border-radius: 100px;
  padding-inline: rem(10);
  &::before {
    content: "";
    display: inline-block;
    width: rem(17);
    height: rem(17);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/gt_icon.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: 1px;
  }
}

/*　ニュートラル
********************************************** */
// 下から上にフェードイン
@mixin neutral_base {
  transform: translateY(rem(20));
  opacity: 0;
  transition: all 0.6s ease;

  &.loaded,
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
}
.load_active,
.scroll_active {
  @include neutral_base;
}
.page_ttl-en {
  transition-delay: 0.2s;
}
@include mq(sp, min, ps) {
  .column_item02 {
    transition-delay: 0.1s;
  }
  .column_item03 {
    transition-delay: 0.2s;
  }
  .column_item04 {
    transition-delay: 0.3s;
  }
}

/* mouse
********************************************** */
.mouse {
  background: #5fa4d2;
  border-radius: 50%;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  @include mq(sp) {
    display: none;
  }
}

/* AddQuickタグ
********************************************** */
.question,
.answer {
  width: fit-content;
  font-family: var(--font-en) !important;
  font-size: rem(16) !important;
  font-weight: 600;
  line-height: 1.7;
  border-radius: 100px;
  margin-block: vw(90) vw(24) !important;
  @include mq(1440) {
    font-size: rem(18) !important;
  }
  @include mq(pc) {
    font-size: rem(20) !important;
  }
  @include mq(sp) {
    font-size: sprem(14) !important;
    margin-block: sprem(45) sprem(10) !important;
  }
}
.question {
  color: var(--clr-primary-dark);
  border: 2px solid var(--clr-primary-dark);
  background-color: var(--clr-wht);
  padding: vw(3) vw(48);
  @include mq(sp) {
    padding: sprem(2) sprem(24);
  }
}
.answer {
  color: var(--clr-wht);
  background-color: var(--clr-primary-dark);
  padding: vw(10) vw(57);
  @include mq(sp) {
    padding: sprem(5) sprem(30);
  }
}
.information--ttl01 {
  font-family: var(--font-editor-ttl) !important;
  font-size: rem(32) !important;
  line-height: 1.1;
  letter-spacing: 0;
  color: var(--clr-primary-dark);
  border-bottom: 1px solid var(--clr-primary-dark);
  padding-block: vw(16);
  margin-block: vw(48) vw(32) !important;
  @include mq(sp) {
    font-size: sprem(20) !important;
    line-height: 1.4;
    padding-block: sprem(8);
    margin-block: sprem(24) sprem(16) !important;
  }
}
.information--ttl02 {
  font-family: var(--font-editor-ttl) !important;
  font-size: rem(24) !important;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: var(--clr-primary-dark);
  background-color: var(--clr-bg-01);
  border-radius: rem(10);
  padding: vw(8) vw(16);
  margin-block: vw(40) vw(32) !important;
  @include mq(sp) {
    font-size: sprem(18) !important;
    padding: sprem(4) sprem(8);
    margin-block: sprem(20) sprem(16) !important;
  }
}
.information--ttl03 {
  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--font-editor-ttl) !important;
  font-size: rem(18);
  line-height: 2;
  letter-spacing: -0.03em;
  color: var(--clr-primary-dark);
  margin-block: vw(40) vw(16) !important;
  &::before {
    content: "";
    display: inline-block;
    width: vw(4);
    height: vw(24);
    background-color: var(--clr-primary-dark);
    border-radius: 100px;
    margin-right: vw(12);
    @include mq(sp) {
      width: sprem(4);
      height: sprem(24);
      margin-right: sprem(12);
    }
  }
  &:first-child {
    margin-top: 0 !important;
  }
  @include mq(1440) {
    font-size: rem(22) !important;
  }
  @include mq(pc) {
    font-size: rem(24) !important;
  }
  @include mq(sp) {
    font-size: sprem(18) !important;
    margin-block: sprem(20) sprem(8) !important;
  }
}
.information--books {
  border-radius: rem(10);
  background: var(--clr-bg-01);
  padding: vw(32) vw(30);
  margin-block: vw(40) !important;
  .information--ttl03 {
    margin-block: 0 vw(14) !important;
    @include mq(sp) {
      margin-block: 0 !important;
    }
  }
  p {
    font-size: rem(17);
    line-height: 2;
    @include mq(1440) {
      font-size: rem(19);
    }
    @include mq(pc) {
      font-size: rem(21);
    }
  }
  @include mq(sp) {
    padding: sprem(16) sprem(15);
    margin-block: sprem(20) 0 !important;
  }
}

/* EN
********************************************** */
font font {
  font-family: var(--font-en);
  font-weight: 400;
}
